<template>
  <div>
    <AlterFormLayout
      modalId="other-solicitation-modal"
      :formTitle="onTitle()"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar solicitação"
      :onSubmitEvent="onSubmit"
    >
      <template #content>
        <OtherSolicitationModalForm />
      </template>
    </AlterFormLayout>
    <success-modal
      ref="success-modal"
      description="Sua solicitação foi enviada."
      buttonText="Ok, entendi!"
      subdescription="Os dados inseridos serão analisados e cadastrados após a validação."
      :protocol="this.protocol"
    />
  </div>
</template>

<script>
import { inject } from "@vue/composition-api";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";
import AlterFormLayout from "../../../layouts/AlterFormLayout.vue";
import { addOtherSolicitation } from "../../../services/other-solicitation/other-solicitation";
import OtherSolicitationModalForm from "./OtherSolicitationModalForm.vue";

export default {
  props: [
    "title",
    "requestData",
  ],
  components: {
    AlterFormLayout,
    OtherSolicitationModalForm,
    SuccessModal,
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  mounted() {},
  data() {
    return {
      protocol: "",
    };
  },
  methods: {
    onTitle() {
      return this.$props.title;
    },
    showSuccessModal() {
      const modal = this.$refs["success-modal"].$children[0];
      modal.show();
    },
    async onSubmit(formData) {
      try {
        this.globalLoading = true;

        formData.category = this.$props.requestData.category;
        formData.subCategory = this.$props.requestData.subCategory;
        formData.manifestation = this.$props.requestData.manifestation;

        this.protocol = await addOtherSolicitation(
          formData,
          formData.files
        );
        this.globalLoading = false;
        this.showSuccessModal();
      } catch (error) {
        this.globalLoading = false;
        console.error(error);
      }
    },
  },
};
</script>
