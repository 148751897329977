<template>
  <div class="analytics">
    <div class="page-container">
      <section class="page-header">
        <h2>Minha analítica financeira</h2>
        <div class="align-reflesh">
          <img
            v-show="loadingPeriod || loadingForce"
            v-if="loadingPeriod || loadingForce"
            class="img-reflesh img-reflesh--loadind"
            src="@/assets/images/refresh.svg"
          />
          <img
            v-show="!loadingPeriod && !loadingForce"
            v-if="!loadingPeriod && !loadingForce"
            class="img-reflesh"
            src="@/assets/images/refresh.svg"
          />
          <p v-text="onDataUpdate()" class="data-feddback-update"></p>
        </div>
      </section>
      <section class="tab-sub-header">
        <div class="d-flex custom-header flex-row flex-wrap mb-3 mt-5 ">
          <div>
            <custom-header-tab
              :headerItems="headerItems"
              :activeHeaderItem="activeTab"
              @onClickHeaderItem="onHeaderItemClicked"
            />
            <div class="mt-4 " v-if="headerItems.length == 0">
              <h5>
                Não há dados para o período informado! Tente consultar outro
                período.
              </h5>
            </div>
          </div>
          <div
            class="d-flex justify-content-center align-items-center flex-row flex-wrap mt-4"
          >
            <span class="p3 pr-4 ">COMPETÊNCIA:</span>
            <vue-monthly-picker
              class="periodo custon-competence-data custon-competence-details"
              v-model="selectedDate"
              :monthLabels="allMonth"
              dateFormat="MM/YYYY"
              :max="this.maxDate"
            ></vue-monthly-picker>
            <b-button
              @click="onClickHandler()"
              variant="primary p-1"
              v-b-modal:details-analytics-modal
              v-show="headerItems.length > 0"
            >
              Ver analítica detalhada
            </b-button>
          </div>
        </div>
      </section>
      <div
        class="d-flex flex-row imagem-analytics"
        v-if="headerItems.length == 0"
      ></div>
      <section class="tab-sub-body">
        <div class="mt-3" v-if="physicalPerson && activeTab == 1">
          <ProducaoPFForm
            :physicalPerson="physicalPerson"
            :year="year"
            :month="month"
            :isShowLink="isSummaryGlosses"
          />
        </div>
        <div class="mt-3" v-if="juridicalPerson && juridicalPerson.length > 0 && activeTab == 2">
          <div
            v-for="juridicalItem in juridicalPerson"
            :key="juridicalItem.name"
          >
            <ProducaoPJForm
              :juridicalPerson="juridicalItem"
              :year="year"
              :month="month"
              :isShowLink="isSummaryGlosses"
            />
          </div>
        </div>
        <div class="mt-3" v-if="transfer && activeTab == 3">
          <ProducaoRepasseForm
            :transfer="transfer"
            :year="year"
            :month="month"
            :isShowLink="isSummaryGlosses"
          />
        </div>
      </section>
      <div class="span" v-if="headerItems.length == 0"></div>
    </div>

    <DetailsAnalyticsModal :year="year" :month="month" />
  </div>
</template>

<script>
import CustomHeaderTab from "../../../components/custom-header-tab/CustomHeaderTab.vue";
import DetailsAnalyticsModal from "../components/modal/DetailsAnalyticsModal.vue";
import ProducaoPFForm from "../components/form/ProducaoPFForm.vue";
import ProducaoPJForm from "../components/form/ProducaoPJForm.vue";
import ProducaoRepasseForm from "../components/form/ProducaoRepasseForm.vue";
import {
  getCompetences,
  getAnalyticsAfterSelectingPeriod,
} from "../../../services/analytics/analytics-services.js";
import { inject } from "@vue/composition-api";
import moment from "moment";
import { getMonthNumber } from "../../../utils/date/date-utils";

export default {
  components: {
    DetailsAnalyticsModal,
    CustomHeaderTab,
    ProducaoPFForm,
    ProducaoPJForm,
    ProducaoRepasseForm,
  },
  name: "Analytics",
  setup() {
    const globalLoading = inject("globalLoading");
    const globalShowInfoError = inject("globalShowInfoError");
    return { globalLoading, globalShowInfoError };
  },
  data() {
    return {
      isSummaryGlosses: false,
      activeTab: null,
      year: null,
      month: null,
      headerItems: [],
      physicalPerson: null,
      juridicalPerson: [],
      transfer: "",
      selectedDate: "",
      dataAnalytical: null,
      maxDate: null,
      loadingPeriod: false,
      loadingForce: false,
      allMonth: [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
      ],
    };
  },
  async mounted() {
    var analyticalDataLoading = this.$store.getters.getAnalyticalDataLoading;
    
    if(analyticalDataLoading.analyticalDataLoading) {
      this.globalLoading += 1;
      this.intervalId = setInterval(()=> {
        var analyticalDataLoading = this.$store.getters.getAnalyticalDataLoading;
        if(!analyticalDataLoading.analyticalDataLoading) {
          this.initialize();
          this.globalLoading -= 1;
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }, 1000);
    }
    else {
    
      await this.initialize()
    }
  },  
  watch: {
    selectedDate: {
      async handler() {
        this.year = this.getYear();
        this.month = this.getMonth();   
        let lastPeriod = this.$store.getters.getAnalyticalData?.analytical?.lastPeriod;     

        if(`${this.year}${this.month}` != lastPeriod){
          await this.getAnalyticsAfterSelectingPeriod();
        }
        else {
          const { analytical, dataAnalytical } = this.$store.getters.getAnalyticalData;
          await this.getAnalyticsSummary(analytical, dataAnalytical);
        }
      },
    },    
  },
  methods: {    
    async initialize(){
      let lastPeriod = this.$store.getters.getAnalyticalData?.analytical
        ?.lastPeriod;
      if (lastPeriod) {
        this.selectedDate = moment(lastPeriod, "YYYYMM");
      }

      this.loadingForce = true;
      const competenceString = await this.getCompetence();
      this.maxDate = moment(competenceString[0]?.competence, "MM/YYYY");      
      this.loadingForce = false;
    },
    async getCompetence() {
      return getCompetences();
    },
    onHeaderItemClicked(itemKey) {
      this.activeTab = itemKey;
    },
    async getAnalyticsSummaryHandler() {
      await this.getAnalyticsAfterSelectingPeriod();
    },
    async getAnalyticsAfterSelectingPeriod() {
      this.loadingPeriod = true;

      try {
        const crm = this.$store.getters.getUserName;
        const response = await getAnalyticsAfterSelectingPeriod(
          crm,
          this.year,
          getMonthNumber(this.month)
        );

        if (!response) {
          const { lastPeriod } = this.$store.getters.getAnalyticalData?.analytical?.lastPeriod;
          if (!this.selectedDate) {
            this.selectedDate = moment(lastPeriod, "YYYYMM");
          }
          this.loadingPeriod = false;
          await this.clearObj();
          return;
        }

        await this.clearObj();

        const { analytical, dataAnalytical } = response;
        this.getAnalyticsSummary(analytical, dataAnalytical);
        this.loadingPeriod = false;
      } catch (error) {
        this.clearObj();
        this.loadingPeriod = false;
        console.error(
          "Error on function getAnalyticsAfterSelectingPeriod: ",
          error
        );

        if (
          error.response.data.error_code === "GATEWAY_TIMEOUT" ||
          error.response.data.message.includes("Read timed out executing")
        ) {
          this.globalShowInfoError = true;
        }
      }
    },
    async checkIsSummaryGlosses() {
      this.isSummaryGlosses = true;
    },
    getAnalyticsSummary(analytical, dataAnalytical) {
      const {        
        physicalPerson,
        juridicalPerson,
        transfer,
      } = analytical;
      this.dataAnalytical = dataAnalytical;

      this.checkIsSummaryGlosses();
      this.physicalPerson = physicalPerson;
      this.juridicalPerson = juridicalPerson;
      this.transfer = transfer;

      this.initializeTab();
    },
    initializeTab() {
      const array = [];
      this.physicalPerson &&
        array.push({
          key: "1",
          label: "Produção PF",
        });
      this.juridicalPerson != null &&
        this.juridicalPerson.length > 0 &&
        array.push({
          key: "2",
          label: "Produção PJ",
        });
      this.transfer &&
        array.push({
          key: "3",
          label: "Produção de repasse",
        });
      if (array.length > 0) this.activeTab = array[0].key;
      this.headerItems = array;
    },
    onClickHandler() {
      this.$emit("eventChangeCompetence", this.year, this.month);
    },
    onDataUpdate() {
      return this.dataAnalytical == null
        ? "Última atualização não identificada."
        : `Última atualização em: ${this.dataAnalytical}.`;
    },
    getYear() {
      if (this.selectedDate) {
        if (this.selectedDate._f == "MM/YYYY")
          return this.selectedDate._i.substr(3, 4);
        else return this.selectedDate._i.substr(0, 4);
      }
    },
    getMonth() {
      if (this.selectedDate) {
        if (this.selectedDate._f == "MM/YYYY")
          return this.selectedDate._i.substr(0, 2);
        if (this.selectedDate._f == "YYYYMM")
          return this.selectedDate._i.substr(4, 6);
        else return this.selectedDate._i.substr(5, 2);
      }
    },
    clearObj() {
      this.headerItems = [];
      this.physicalPerson = null;
      this.juridicalPerson = [];
      this.transfer = "";
      this.activeTab = null;
    },
  },
};
</script>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  max-width: 1326px;
}

.analytics {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.page-header {
  width: 100%;
  display: grid;
  grid-column: 0;
  text-align: center;
}

@media (min-width: 580px) {
  .page-header {
    grid-template-columns: 250px auto;
  }

  .align-reflesh {
    justify-content: flex-end !important;
  }
}

.tab-sub-header {
  align-items: center;
  justify-content: left;
  vertical-align: middle;
}

.tab-sub-header {
  align-items: center;
  justify-content: left;
  vertical-align: middle;
}

.btn {
  width: 19.4rem;
  height: 3.8rem;
  align-items: center;
}

.imagem-analytics {
  background-image: url("../../../assets/images/ilus_analitica.svg");
  background-repeat: no-repeat;
  background-position: 85% 90%;
  width: 100%;
}

.span {
  display: flex;
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--light-4);
}

.data-feddback-update {
  text-align: center;
}

.align-reflesh {
  width: 100%;
  display: flex;
  justify-content: center;
}

.font-weight-bold {
  width: 50%;
}

.img-reflesh {
  height: 60%;
  padding-right: 0.2rem;
  &--loadind {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.periodo {
  padding-right: 6px;
}
.custon-competence-data {
  padding-top: 1rem;
}
.custon-competence-details {
  padding-bottom: 1rem;
}
.custom-header {
  justify-content: space-between;
}

@media (max-width: 577px) {
  .custom-header {
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
