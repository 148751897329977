<template>
  <div class="app-background">
    <NewNavBar>
      <template slot="menuAdm"><MenuAreaAdm /></template>
      <template slot="menu"><Menu :key="getUser()"/></template>
    </NewNavBar>
    <Content v-show="globalLoading == 0 && globalShowInfoError == 0" :key="getUser()" />

    <transition name="fade">
      <div v-if="globalLoading > 0" class="loading-block-container">
        <RotateSquare />
        <div>
          <p>Aguarde...</p>
        </div>
      </div>

      <div v-if="globalShowInfoError > 0" class="loading-block-container">
        <div class="config-body">
          <div>
            <div class="div-img">
              <div class="img"></div>
            </div>

            <p class="T14 mt-4">
              Parece que a busca pelas informações levou mais tempo do que o
              esperado!
            </p>
            <p class="T14">
              Estamos investigando o ocorrido. Por favor, tente novamente mais
              tarde.
            </p>
            <div class="config-button">
              <b-button
                @click="onActioHandler()"
                class="button"
                variant="primary"
                id="ok"
                >Ok, entendi!
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <ProfileImageModal />
    <ChangePasswordModal />
    <ChatWhatsapp v-if="!this.$store.getters.isAdmin" />
    <CommunicationPassword v-if="!this.$store.getters.isAdmin" />
    <Communication />
  </div>
</template>

<script>
import NewNavBar from "../components/base/NewNavBar.vue";
import ChatWhatsapp from "../components/chat-whatsapp/ChatWhatsapp.vue";
import Content from "../components/base/Content.vue";
import { provide, ref } from "@vue/composition-api";
import ProfileImageModal from "../modules/user-profile-management/components/profile-image-upload/ProfileImageModal.vue";
import ChangePasswordModal from "../modules/user-profile-management/components/change-password/ChangePasswordModal.vue";
import Menu from "./Menu.vue";
import RotateSquare from "../components/rotate-square/RotateSquare.vue";

import BlipClient from "../components/blip-chat/blip-chat";
import Communication from "../modules/communication/views/Communication.vue";
import CommunicationPassword from "../modules/communication-password/views/CommunicationPassword.vue";
import { isMobile } from "../utils/mobile/mobile";
import MenuAreaAdm from "../components/base/MenuAreaAdm.vue";
import { getNameUser } from "../utils/user/user";

export default {
  name: "base-layout-new-menu",
  components: {
    Content,
    NewNavBar,
    ProfileImageModal,
    ChangePasswordModal,
    Menu,
    RotateSquare,
    ChatWhatsapp,
    Communication,
    MenuAreaAdm,
    CommunicationPassword,
  },

  mounted() {
    if (!isMobile() && !this.$store.getters.isAdmin) {
      const blipClient = BlipClient.getInstance();
      blipClient.build();
    }
  },
  setup() {
    const globalLoading = ref(0);
    const globalShowInfoError = ref(0);
    provide("globalLoading", globalLoading);
    provide("globalShowInfoError", globalShowInfoError);
    return { globalLoading, globalShowInfoError };
  },
  methods: {
    getUser() {
      return getNameUser();
    },
    onActioHandler() {
      this.globalShowInfoError -= 1;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#blip-chat-container #blip-chat-open-iframe {
  bottom: 90px !important;
  right: 20px !important;
  width: 70px !important;
  height: 70px !important;
}

@media only screen and (max-width: 600px) {
  #blip-chat-container #blip-chat-open-iframe {
    display: none;
  }
}
.cards {
  width: 100%;
  display: grid;
  border-style: none;
  grid-column: 0;
}
.info-crm {
  display: grid;
  grid-template-columns: 240px 30px;
}
.input-file-adm {
  border: none;
}
.config-img-adm {
  cursor: pointer;
}
</style>
