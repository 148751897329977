import store from "../../store";
import axios from "../http-client/axios";
import { GORIJA_URL } from "../urls/base-urls";

export async function getAnalyticsAfterSelectingPeriod(crm, year, month) {
  if (checkUseCache(year, month)) {
    return store.getters.getAnalyticalData;
  }

  const reponseAPi = await getAnalyticsSummary(crm, year, month);
  const { period } = reponseAPi;
  const requests = requestAnalyticalMapper(reponseAPi, true, period);
  return requests;
}

export async function getAnalyticsAfterLogin(crm, year, month) {
  const reponseAPi = await getAnalyticsSummary(crm, year, month);
  const { period } = reponseAPi;
  const requests = requestAnalyticalMapper(reponseAPi, true, period);
  store.dispatch("setStoreAnalyticalData", requests);
  return requests;
}

async function getAnalyticsSummary(crm, year, month) {
  let url = "";

  if (year && month) url = `/year/${year}/month/${month}`;

  const resp = await axios({
    // url: `http://localhost:8298/financial-summary${url}`,
    url: `${GORIJA_URL}/financial-summary${url}`,

    method: "get",
    data: {},
    headers: {
      "content-type": "application/json"
    },
  });

  return resp.data;
}

function requestAnalyticalMapper(analytical, updateDate, competence) {
  let requests = {
    analytical: { ...analytical },
    competence: competence,
    dataAnalytical: updateDate
      ? new Date().toLocaleString("pt-br")
      : store.getters.getAnalyticalData.dataAnalytical,
  };

  return requests;
}

export async function getCompetences() {
  const resp = await axios.get(`${GORIJA_URL}/current-competency`);
  return resp.data;
}

function checkUseCache(year, month) {
  if(store.getters.getAnalyticalData ) {
    const lastPeriod = store.getters.getAnalyticalData?.analytical?.lastPeriod;
    return (
      year != null &&
      month != null &&
      lastPeriod != null &&
      lastPeriod == month.concat("/").concat(year)
    );    
  } else {
    return false
  }
}