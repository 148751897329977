const JMJSolicitation = [   
    {
        id: "CHANGE_DUE_DATE",
        name: "Alterar data de vencimento",
        category: 260,
        subCategory: 601,
        manifestation: 944
    },
    {
        id: "CHANGE_PAYMENT_METHOD",
        name: "Alterar forma de pagamento",
        category: 260,
        subCategory: 601,
        manifestation: 945
    },
    {
        id: "MEMBERSHIP_INCLUSION_DEPENDENTS",
        name: "Adesão e inclusão dependentes",
        category: 260,
        subCategory: 601,
        manifestation: 946
    },
    {
        id: "EXCLUSION_DEPENDENTS",
        name: "Cancelamento e exclusão de dependentes",
        category: 260,
        subCategory: 601,
        manifestation: 947
    },
    {
        id: "SECOND_COPY_CONTRACT",
        name: "2º via contrato",
        category: 260,
        subCategory: 601,
        manifestation: 948
    },
    {
        id: "SECOND_COPY_CARD",
        name: "2º via carteirinha",
        category: 260,
        subCategory: 601,
        manifestation: 1141
    },
    {
        id: "LETTER_GRACE",
        name: "Carta de Carência",
        category: 173,
        subCategory: 423,
        manifestation: 668
    }
];
export default JMJSolicitation;