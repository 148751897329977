<template>
  <div v-if="isAbleRole(RoleList.AUTHORITY_ADMIN)" class="admin-bar">
    <div
      class="config-menu-adm"
      :class="
        isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER)
          ? 'content-between justify-content-between'
          : 'content-end justify-content-end'
      "
    >
      <b-button
        @click="cancel"
        class="my-3 mr-3 input-mobile-adm clickable"
        variant="outline-primary"
        v-show="isAbleRole(RoleList.AUTHORITY_USER_IS_CUSTOMER)"
      >
        {{ isMobile() ? "Área administrativa" : "Acessar área administrativa" }}
      </b-button>
      <div class="info-crm d-flex align-items-center">
        <div class="d-flex justify-content-center">
          <p class="h5 lb-cooperado">
            {{ isMobile() ? "COOPERADO:" : "VISUALIZAR COMO COOPERADO:" }}
          </p>
        </div>
        <div class="input-file-adm--margin">
          <b-form-input
            type="text"
            class="input-file-adm"
            v-model.lazy.trim="crmCoop"
          />
        </div>
        <div>
          <img
            src="@/assets/images/refresh-adm.svg"
            class="config-img-adm"
            @click="onActionHandler"
            alt="Kitten"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RoleList } from "../../static-data/RoleList";
import RoutesPaths from "../../router/router-structure/routes-paths";
import { inject } from "@vue/composition-api";
import { createToast } from "../../components/toast/toast";
import isAbleRole from "../../utils/permissions/is-able-role";
import { isMobile } from "../../utils/mobile/mobile";
import { Firebase } from "../../services/firebase/log-firebase";

export default {
  computed: {
    RoleList() {
      return RoleList;
    },
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  async mounted() {
    this.initLog();
  },
  data() {
    return {
      crmCoop: "",
      crmValid: false,
      firebase: null,
    };
  },
  methods: {
    initLog() {
      this.firebase = new Firebase();
    },
    isMobile: isMobile,
    isAbleRole: isAbleRole,
    async cancel() {
      const crmCoop = this.$store.getters.getUserName;
      await this.$store.dispatch("cancelLoginInspect");
      await this.$store.dispatch("clearUserInformation");
      await this.$store.dispatch("clearAnalyticalData");
      if (
        !this.$router.currentRoute.matched.some(
          ({ path }) => path == RoutesPaths.contentManagement.rootName()
        )
      ) {
        this.$router.push(RoutesPaths.contentManagement.rootName());
      }
      this.crmCoop = "";
      this.firebase.addInfoLog(
        `Inspecionar CRM Fim - O admin ${this.$store.getters.getUserNameAdmin} finalizou uma sessão como ${crmCoop}`,
        "ACESSO"
      );
    },
    async validStatusCrm(status) {
      return ["ACTIVE", "SUSPENDED", "DELETED", "AWAY"].includes(
        status.toUpperCase()
      );
    },
    async accessCooperativeArea() {
      if (this.crmCoop) {
        const isCoop =
          this.$store.getters.roles.indexOf(
            RoleList.AUTHORITY_USER_IS_CUSTOMER
          ) >= 0;

        const response = await this.requestLoginInspect();

        await this.$store.dispatch("initializePermissions", response);
        this.$store.dispatch("verifyUseTerm");
        this.$store.dispatch("verifyStatus");
        const crm = this.$store.getters.getUserName;
        this.$store.dispatch("requestUserInformation", crm);
        this.$store.dispatch("requestAnalyticalData", crm);
        this.$store.dispatch("requestNewnessData");
        this.firebase.addInfoLog(
          `Inspecionar CRM Início - O admin ${this.$store.getters.getUserNameAdmin} iniciou uma sessão como ${crm}`,
          "ACESSO"
        );
        if (!isCoop) {
          this.$router.push({ path: RoutesPaths.home.rootName() });
        }
      } else {
        createToast("Informação", "Um crm deve ser informado.", "info");
      }
    },
    async requestLoginInspect() {
      await this.$store.dispatch("cancelLoginInspect");
      try {
        return await this.$store.dispatch("requestLoginInspect", this.crmCoop);
      } catch (err) {
        throw new Error("CRM_INVALIDO");
      }
    },
    async onActionHandler() {
      try {
        this.globalLoading += 1;
        await this.accessCooperativeArea();
        this.globalLoading -= 1;
      } catch (err) {
        console.error(err);
        this.globalLoading -= 1;

        if (err == "Error: CRM_INVALIDO") {
          const delay = 120000000;
          const msg = "O crm informado é invalido!";
          createToast("Informação", msg, "info", delay);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-end {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
}

.content-between {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
}
.lb-cooperado {
  text-align: end;
}
.config-menu-adm {
  height: 100%;
}
.admin-bar {
  padding-left: 4rem;
  padding-right: 5rem;
  background: #e9f1ef;
  height: 7rem;
  width: 100%;
}
.info-crm {
  height: 2.5rem;
  display: grid;
  grid-template-columns: 150px auto 30px;
}
.input-file-adm {
  &--margin {
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  height: 100%;
  border: none;
}
.config-img-adm {
  height: 100%;
  width: 27px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .input-mobile-adm {
    font-size: 1rem !important;
  }
}
.clickable {
  cursor: pointer;
}
</style>
