<template>
  <div class="custon-cards-resultado-individual">
    <div class="custom-header">
      <p class="p-201">
        {{ this.$props.title }}
      </p>
      <div class="span-card mt-2"></div>
    </div>
    <div class="custom-body">
      <div v-for="(item, index) in items" :key="index">
        <p class="desc-custon p-202">
          <img v-if="item.check" class="img-icons"
            src='../../../assets/images/icon-check.svg'
          />
          <img
            v-if="!item.check"
            class="img-icons"
            src='../../../assets/images/icon-error.svg'
          />
          {{ item.description }}
        </p>
      </div>
    </div>
    <div class="custom-footer">
      <div class="teste1">
        <p
          class="p custon-pointer d-flex align-content-center align-items-center flex-wrap"
          @click="onActioHandler(typeActionHandler)"
        >
          <img
            class="img-saiba-mais"
            src="../../../assets/images/MDI-information.svg"
          />
          Entenda este indicador
        </p>
      </div>      
      <div class="d-flex align-content-end">
        <p
        class="custom-align w-100"
          v-text="this.onGetAdicional()"
          :class="isMobile() ? 'p-201-gd' : 'p-201'"
        ></p>
      </div>      
    </div>
  </div>
</template>

<script>
import { isMobile } from '../../../utils/mobile/mobile';

export default {
  props: [
    "title",
    "porcentage",
    "onAction",
    "typeAction",
    "items",
  ],
  setup(props) {
    const onActioHandler = function(event) {
      props.onAction(event);
    };

    return { onActioHandler };
  },
  data() {
    return {
      typeActionHandler: "",
    }
  },
  components: {},
  mounted() {
    this.typeActionHandler = this.$props.typeAction ? this.$props.typeAction : "";
  },
  methods: {
    isMobile: isMobile,
    onGetAdicional() {
      return "Ganho adicional: " + this.$props.porcentage;
    },
  },
};
</script>

<style lang="scss" scoped>
.custon-cards-resultado-individual {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  width: 48.3%;
  height: 19.8rem;

  @media screen and (max-width: 1112px) {
    height: 25rem;
    padding-bottom: 0rem;
  }
}
.custom-header {
  height: 15%;
}
.custom-body {
  height: 70%;
  padding-top: 1.5rem;
}
.custom-footer {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 1.4rem !important;
}
.custon-pointer {
  cursor: pointer;
}

@media (min-width: 992px) {
  .custom-footer {
    display: grid;
    grid-template-columns: 125px auto;
    padding-bottom: 1.4rem !important;
  }
}

.custom-align {
  text-align: right;
}
.is-mobile {
  .p-4-m {
    color: var(--green-3);
    font-size: 1rem;
  }
  .p-6 {
    color: var(--green-3);
    font-size: 1.4rem;
  }

  .p10 {
    color: var(--secondary-green);
    font-size: 1.4rem;
  }

  .p19 {
    color: var(--secondary-green);
    font-size: 2rem;
  }

  .p-c-19 {
    color: var(--green-3);
    font-size: 2rem;
  }

  .p-c-199 {
    color: var(--primary);
    font-size: 1.4rem;
  }
  .p-199-m {
    color: var(--primary);
    font-size: 1rem;
  }
  .p-199 {
    color: var(--primary);
    font-size: 1.2rem;
  }

  .p-c-200 {
    color: var(--orange-2);
    font-size: multiplier-default-font-size(19); // 48
  }

  .p-200 {
    color: var(--orange-2);
    font-size: multiplier-default-font-size(3); // 16px
  }

  .p-201 {
    color: var(--primary);
    font-size: 1.4rem;
  }
  .p-201-gd {
    color: var(--primary);
    font-size: 1.1rem;
  }
  .p-202 {
    color: var(--grey-2);
    font-size: 1rem;
    margin-bottom: 0rem;
  }
}
.p10 {
  color: var(--secondary-green);
  font-size: multiplier-default-font-size(3); // 16
}

.p19 {
  color: var(--secondary-green);
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-19 {
  color: var(--green-3);
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-199 {
  color: var(--primary);
  font-size: multiplier-default-font-size(19); // 48
}

.p-199 {
  color: var(--primary);
  font-size: multiplier-default-font-size(3); // 16px
}

.p-c-200 {
  color: var(--orange-2);
  font-size: multiplier-default-font-size(19); // 48
}

.p-200 {
  color: var(--orange-2);
  font-size: multiplier-default-font-size(3); // 16px
}

.p-201 {
  color: var(--primary);
  font-size: multiplier-default-font-size(5); // 20px
}

.p-accumulated {
  color: var(--green-2);
  font-size: multiplier-default-font-size(2); //14px
}

.p-202 {
  color: var(--grey-2);
  font-size: multiplier-default-font-size(2);
  margin-bottom: 0rem;
}

.p-16-custom {
  color: var(--green-2);
  font-size: multiplier-default-font-size(3); // 16
}
.span-card {
  width: 100%;
  height: 0.05rem;
  border-radius: 20%;
  background-color: var(--grey-3);
}
.img-icons {
  height: 2rem;
  width: 5%;
  @media screen and (max-width: 1112px) {
    width: 13%;
  }
}
.desc-custon {
  padding-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .p-201 {
    font-size: multiplier-default-font-size(4) 
  }
  .custom-header {
    height: 20%;
  }
  .custom-body {
    height: 60%;
  }

  .p-202 {
    font-size: multiplier-default-font-size(1);
  }
}
@media (max-width: 473px) {
  .p-201 {
    font-size: multiplier-default-font-size(3) 
  }
  .custom-body {
    height: 75%;
  }
  .custom-footer {
    height: 10%;
  }
}

@media (max-width: 432px) {
  .p-201 {
    font-size: multiplier-default-font-size(2) 
  }
  .p-202 {
    font-size: multiplier-default-font-size(1);
  }
}

@media (max-width: 392px) {
  .p-201 {
    font-size: multiplier-default-font-size(1) 
  }
  .p-202 {
    font-size: multiplier-default-font-size(0);
  }
}
</style>
