<template>
  <div>
    <FormLayout
      :tabs="this.tabs"
      :leftAction="this.leftAction"
      :onChangeTab="onChangeTabIndex"
    ></FormLayout>
  </div>
</template>

<script>
import Vue from "vue";
import FormLayout from "../../../../layouts/FormLayout.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";

Vue.component("general-information-training", () =>
  import("./GeneralInformationTrainingForm.vue")
);

Vue.component("trail-information-training", () =>
  import("./TrailInformationTrainingForm.vue")
);

export default {
  components: {
    FormLayout,
  },
  async mounted() {
    if (this.$route.params.id) {
      this.leftAction.text = "Editar Treinamento";
    }
  },
  data: function() {
    return {
      currentTabIndex: 0,
      leftAction: {
        text: "Novo Treinamento",
        onClick: this.leftActionClickHandler,
      },
      leftLinkNavigation: this.leftHistoryNavigation,

      tabs: [
        {
          title: "Informações Gerais",
          formComponent: "general-information-training",
        },
        {
          title: "Informações da Trilha",
          formComponent: "trail-information-training",
        },
      ],
    };
  },
  methods: {
    onChangeTabIndex(newValue) {
      this.currentTabIndex = newValue;
    },
    leftActionClickHandler() {
      this.$router.push(RoutesPaths.trainingManagement.rootName());
    },
    leftHistoryNavigation() {
      this.$router.push({
        path: RoutesPaths.requestHistory.rootName(),
        params: {
          serviceType: this.tabs[this.currentTabIndex].serviceType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
