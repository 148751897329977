<template>
  <div class="assembly-list">
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0 custom-margim-top-header-input">
          <b-form-input
            id="input-1"
            class="text-left"
            v-model="filter"
            type="text"
            placeholder="Busque por evento ou data..."
          ></b-form-input>
        </b-col>
        <b-col class="pl-0 pr-0 text-right custom-margim-top-header">
          <b-button
            id="button-generate-report"
            class="my-3"
            variant="primary"
            @click="onCreateReportClick()"
          >
            Gerar relatório por CRM
          </b-button>
        </b-col>
      </template>
      <template slot="table">
        <div class="scrollme">
          <b-table
            class="table-custom-assembly spaced-text"
            :fields="fields"
            :items="items"
            v-show="!this.loading"
            show-empty
            empty-text="Nenhuma informação para exibir!"
          >
            <template #cell(report)="data">
              <div
                class="custom-row-table d-flex justify-content-center align-items-center"
              >
                <div
                  :id="'report-enabled-' + data.item.id"
                  v-if="data.item.participants > 0"
                  :class="'custom-icon-report-enable'"
                  class="mt-4 justify-content-center align-items-center custom-icon-report"
                  @click="onReportParticipationClick(data.item)"
                >
                  <b-tooltip
                    placement="top"
                    :target="'report-enabled-' + data.item.id"
                    >Ver relatório
                  </b-tooltip>
                </div>
                <div
                  :id="'report-disable-' + data.item.id"
                  v-if="data.item.participants == 0"
                  :class="'custom-icon-report-disable'"
                  class="mt-4 justify-content-center align-items-center custom-icon-report"
                >
                  <b-tooltip
                    placement="top"
                    :target="'report-disable-' + data.item.id"
                    >Relatório não está disponível
                  </b-tooltip>
                </div>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="custom-actions d-flex justify-content-between">
                <div
                  class="icon custom-icon-edit"
                  @click="onEditClick(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Editar"
                ></div>
                <div
                  class="icon custom-icon-delete icon-delete-enable"
                  @click="onDelete(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Excluir"
                ></div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <div
          class="d-flex bd-highlight mb-3 justify-content-between"
          :class="isMobile() ? 'flex-column-reverse' : 'flex-row'"
          style="width: 100%;"
        >
          <div :class="isMobile() ? 'align-self-center' : 'me-auto'">
            <b-button
              class="my-3"
              variant="primary"
              @click="onCreateClick()"
              v-show="!this.loading"
            >
              Cadastrar Assembleia
            </b-button>
          </div>
          <div
            class="mx-auto"
            :class="isMobile() ? '' : 'pagination-training align-self-center'"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="perPage"
              aria-controls="my-table"
              size="lg"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
    />
    <ErrorModal
      modalId="pdf-file-send-modal"
      title="Algo deu errado!"
      submitButtonText="Ok, entendi!"
      ref="send-modal"
    >
      <template slot="description">
        <div class="justify-content-center text-center">
          <div>
            <p class="p1 mb-3">
              Não foi possível concluir a exclusão, pois alguns participantes não puderam ser removidos do evento. 
            </p>
          </div>
          <div>
            <p class="p1 mb-5">
              Por favor, tente novamente.
            </p>
          </div>
        </div>
      </template>
    </ErrorModal>
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Tem certeza que deseja excluir esta assembleia?
        </p>
      </template>
    </attention-modal>
  </div>
</template>
<script>
import RoutesPaths from "../../../router/router-structure/routes-paths";
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { resolve_path_url } from "../../../utils/image/image-utils";
import { isMobile } from "../../../utils/mobile/mobile";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";

import AttentionModal from "../../../components/base-modals/AttentionModal.vue";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";

import {
  getAssemblies,
  getAssemblyReport,
  deleteAssembly,
} from "../../../services/assembly-management/assembly-management-service";
import ErrorModal from "../../../components/base-modals/ErrorModal.vue";

export default {
  components: {
    TableVisualizationLayout,
    AttentionModal,
    SuccessModal,
    ErrorModal
  },
  mounted() {
    this.getAssemblies();
  },
  watch: {
    page: {
      handler() {
        this.getAssemblies();
      },
    },
    filter: {
      handler() {
        if (this.filter.length > 2 || this.filter.length == 0) {
          this.getAssemblies();
        }
      },
    },
  },
  data() {
    return {
      leftAction: {
        text: "Participação Assembleia",
        onClick: this.leftActionClickHandler,
      },
      leftInfo: "",
      filter: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      itemToDelete: {},
      itemToUpdateStatus: {},
      statusUpdate: null,
      textModalUpdateStatus: null,
      textConfirm: null,
      msgSuccess: "",
      fields: [
        {
          key: "event",
          label: "Evento",
          thStyle: { width: "40%" },
        },
        {
          key: "eventDate",
          label: "Data",
          thStyle: { width: "10%" },
        },
        {
          key: "participants",
          label: "Participações",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "report",
          label: "Relatório",
          class: "text-center",
          thStyle: { width: "10%" },
        },
        {
          key: "actions",
          label: "Ações",
          class: "text-center",
          thStyle: { width: "1%" },
        },
      ],
      items: [],
      loading: true,
      resolve_path_url,
    };
  },
  methods: {
    isMobile: isMobile,
    onEditClick(item) {
      this.$router.push({
        path: RoutesPaths.contentManagement.assemblyManagementForm(item?.id),
      });
    },
    async onDelete(item) {
      if (!item.action.canDelete) return;

      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    async onDeleteConfirm(confirmed) {
      if (!confirmed) return;

      var response = await deleteAssembly(this.itemToDelete.id);
      if (response.wasDeleted) {
        this.msgSuccess = "Assembleia excluída com sucesso!!";
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.getAssemblies();
      } else {
        const modal = this.$refs["send-modal"].$children[0];
        modal.show();
      }
    },
    async onGenerateReport(item) {
      if (item.participants > 0) {
        // const reportResponse = await getTrainingsReport(item.id);
        // anchorBase64Download(
        //   null,
        //   "application/xls",
        //   item.title + reportResponse.extension,
        //   reportResponse.fileBase64
        // );
      }
    },
    async getAssemblies() {
      try {
        this.loading = true;
        const assembliesResponse = await getAssemblies(
          this.filter,
          this.page - 1,
          this.perPage
        );

        this.items = assembliesResponse.items;
        this.totalSize = assembliesResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onCreateClick() {
      this.$router.push({
        path: RoutesPaths.contentManagement.assemblyManagementForm(),
      });
    },
    onCreateReportClick() {
      this.$router.push({
        path: RoutesPaths.contentManagement.assemblyParticipantsReportManagement(),
      });
    },
    async onReportParticipationClick(assembly) {
      if (assembly) {
        const reportResponse = await getAssemblyReport(assembly.id);

        anchorBase64Download(
          null,
          "application/xls",
          assembly.event + ".xlsx",
          reportResponse.fileBase64
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-icon-report {
  &-enable {
    background-image: url("../../../assets/icons/training-report-enable.svg");

    &:hover {
      cursor: pointer;
    }
  }

  &-disable {
    background-image: url("../../../assets/icons/training-report-disable.svg");
  }

  background-repeat: no-repeat;
  width: 22px;
  height: 36px;
}

.custom-icon-edit {
  background-image: url("../../../assets/icons/pencil-outline.svg");
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/pencil-outline-houver.svg");
    background-position: center;
  }
}
.custom-icon-delete {
  background-image: url("../../../assets/icons/trash-can-outline.svg");
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(213, 36, 83, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
    background-position: center;
  }
}
.custom-actions div:not(:last-child) {
  margin-right: 1.2rem;
}

#button-generate-report {
  margin-top: 0 !important;
}
.custom-margim-top-header {
  margin-top: 18px;
}
.custom-margim-top-header-input {
  margin-top: 22px;
}
</style>
<style lang="scss">
.assembly-list .table td {
  vertical-align: middle;
}
</style>
