<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0">
          <b-row class="mt-4 mb-4" align-h="between">
            <b-col class="pl-0 pr-0" sm="4">
              <b-form-input
                id="input-1"
                class="text-left"
                v-model="filterTitle"
                type="text"
                placeholder="Busque por nome do certificado..."
              ></b-form-input>
            </b-col>
            <b-col class="pl-0 pr-0" sm="4">
              <b-select v-model="filterTypeId" :options="filterTypes" />
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template slot="table">
        <div class="scrollme">
          <b-table
            class="table-custom-cerificate spaced-text"
            :fields="fields"
            :items="items"
            v-show="!this.loading"
            show-empty
            empty-text="Nenhuma informação para exibir!"
          >
            <template #cell(endDate)="data">
              {{
                data.item.endDate == null ? "Contínuo" : data.item.endDate
              }}
            </template>
            <template #cell(actions)="data">
              <div class="custom-actions d-flex justify-content-between">
                <div
                  class="icon custom-icon-edit"
                  @click="goForm(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Editar"
                ></div>
                <div
                  class="icon"
                  :class="
                    data.item.action.canDelete
                      ? 'icon-delete-enable'
                      : 'icon-delete-disable'
                  "
                  @click="onDelete(data.item)"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Excluir"
                ></div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <div
          class="d-flex bd-highlight mb-3 justify-content-between"
          :class="isMobile() ? 'flex-column-reverse' : 'flex-row'"
          style="width: 100%;"
        >
          <div :class="isMobile() ? 'align-self-center' : 'me-auto'">
            <b-button
              class="my-3"
              variant="primary"
              @click="goForm()"
              v-show="!this.loading"
            >
              Novo Certificado
            </b-button>
          </div>
          <div
            class="mx-auto"
            :class="isMobile() ? '' : 'pagination-cerificate align-self-center'"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="perPage"
              aria-controls="my-table"
              size="lg"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
    <success-modal
      ref="success-modal"
      :description="this.msgSuccess"
      buttonText="Ok, entendi!"
    />
    <attention-modal
      modalId="delete-attention-modal"
      title="Atenção!"
      submitButtonText="Sim"
      cancelButtonText="Não"
      ref="attention-modal"
      :onAction="this.onDeleteConfirm"
    >
      <template slot="description">
        <p class="p1 mb-5 confirm-text">
          Tem certeza que deseja excluir este certificado?
        </p>
      </template>
    </attention-modal>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import {
  dateToLocaleDateString,
  dateToTime,
} from "../../../utils/date/date-utils";
import RoutesPaths from "../../../router/router-structure/routes-paths";
import { resolve_path_url } from "../../../utils/image/image-utils";
import { isMobile } from "../../../utils/mobile/mobile";

import {
  getCertificates,
  deleteCertificate,
} from "../../../services/certificate/certificateManagementService";

import AttentionModal from "../../../components/base-modals/AttentionModal.vue";
import SuccessModal from "../../../components/base-modals/SuccessModal.vue";

export default {
  components: {
    TableVisualizationLayout,
    AttentionModal,
    SuccessModal,
  },
  mounted() {
    this.getCertificates();
  },
  watch: {
    page: {
      handler() {
        this.getCertificates();
      },
    },
    filterTypeId: {
      handler() {
        this.getCertificates();
      },
    },
    filterTitle: {
      handler() {
        if (this.filterTitle.length > 2 || this.filterTitle.length == 0) {
          this.getCertificates();
        }
      },
    },
  },
  data() {
    return {
      filterTypes: [
        { value: "CURRENT", text: "CERTIFICADOS VIGENTES" },
        { value: "FINISHED", text: "CERTIFICADOS FINALIZADOS" },
        { value: "FUTURES", text: "CERTIFICADOS FUTUROS" },
      ],
      filterTypeId: "CURRENT",
      filterTitle: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      itemToDelete: {},
      itemToUpdateStatus: {},
      statusUpdate: null,
      textModalUpdateStatus: null,
      textConfirm: null,
      msgSuccess: "",
      leftAction: {
        text: "Gestão de certificados",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Para acessar as informações dos certificado clique no botão editar.",
      fields: [
        {
          key: "name",
          label: "Nome",
          thStyle: { width: "40%" },
        },

        {
          key: "initialDate",
          label: "Início",
          thStyle: { width: "15%" },
        },
        {
          key: "endDate",
          label: "Fim",
          thStyle: { width: "15%" },
        },
        {
          key: "numberTrainingLinked",
          label: "Treinamentos vinculados",
          class: "text-center",
          thStyle: { width: "20%" },
        },
        {
          key: "actions",
          label: "Ações",
          class: "text-center",
          thStyle: { width: "5%" },
        },
      ],
      items: [],
      loading: true,
      resolve_path_url,
    };
  },
  methods: {
    isMobile: isMobile,
    async encodeStr(id) {
      const response = btoa(id);
      return response;
    },
    goForm(item) {
      this.$router.push({
        path: RoutesPaths.certificationManagement.form(item?.id),
      });
    },
    async onDelete(item) {
      if (!item.action.canDelete) return;

      this.itemToDelete = item;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    onDeleteConfirm(confirmed) {
      if (!confirmed) return;

      deleteCertificate(this.itemToDelete.id).then(() => {
        this.msgSuccess = "Certificado excluído com sucesso!!";
        const modal = this.$refs["success-modal"].$children[0];
        modal.show();
        this.getCertificates();
      });
    },
    async getCertificates() {
      try {
        this.loading = true;
        const cerificateResponse = await getCertificates(
          this.filterTypeId,
          this.filterTitle,
          this.page - 1,
          this.perPage
        );

        this.items = cerificateResponse.items;
        this.totalSize = cerificateResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getInformation", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    onFormatDate(value) {
      let valueSplit = value.split(" ");
      let horaSplit = valueSplit[1].split(":");
      return `${valueSplit[0]} às ${horaSplit[0]}h${horaSplit[1]}`;
    },
    dateToTime: dateToTime,
    dateToLocaleDate: dateToLocaleDateString,
  },
};
</script>
<style lang="scss">
div.table-custom-cerificate td {
  color: #7a7e83;
  padding-left: 2rem;
}
div.table-custom-cerificate th {
  padding-left: 2rem;
}
</style>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.itemleft {
  position: absolute;
  left: 0;
}
.icon {
  background-repeat: no-repeat;
  width: 32px;
  height: 25px;
  background-position: center;

  &-ordering {
    background-image: url("../../../assets/icons/arrow-collapse-vertical.svg");
    float: left;
  }

  &-folder {
    background-image: url("../../../assets/icons/folder-outline.svg");
    margin: auto;
  }

  &-edit {
    background-image: url("../../../assets/icons/pencil-outline.svg");
    float: left;
    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/pencil-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-enable {
    background-image: url("../../../assets/icons/trash-can-outline.svg");

    &:hover {
      background: #fbe9ed 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/trash-can-outline-houver.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &-delete-disable {
    float: left;
    background-image: url("../../../assets/icons/trash-can-outline-disable.svg");

    &:hover {
      cursor: default !important;
    }
  }

  &-duplicate {
    background-image: url("../../../assets/icons/clone.svg");
    float: left;
    margin-left: 6px;

    &:hover {
      background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
      background-image: url("../../../assets/icons/clone-hover.svg");
      background-position: center;
      border-radius: 30px;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.confirm-text {
  margin: auto;
}
.custom-actions {
  width: 90%;
}

.custom-cursor-status {
  &-disable {
    &:hover {
      cursor: default !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
.icon {
  padding: 1.4rem;
}

.custom-icon-edit {
  background-image: url("../../../assets/icons/pencil-outline.svg");
  background-repeat: no-repeat;
  width: 32px;
  height: 25px;
  border-radius: 30px;
  background-position: center;
  cursor: pointer;
  &:hover {
    background: rgba(1, 157, 95, 0.1) 0% 0% no-repeat padding-box;
    background-image: url("../../../assets/icons/pencil-outline-houver.svg");
    background-position: center;
  }
}

.custom-actions div:not(:last-child) {
  margin-right: 1.2rem;
}
.pagination-cerificate ul {
  margin-bottom: 0rem;
}
.scrollme {
  overflow-x: auto;
}
</style>
