import { isMobile } from "../../utils/mobile/mobile";
import FirebaseClient from "./db-firebase";
import { addDoc, collection, serverTimestamp} from "firebase/firestore/lite";
import initializeJsonFromLocalStorage from "../../utils/storage/initialize-storage-utils";
import store from "../../store/index";
import { UAParser } from 'ua-parser-js';
import LinkType from '../../static-data/Links';

function addMethod(object, name, fn) {
  var old = object[name];
  object[name] = function() {
    if (fn.length == arguments.length) return fn.apply(this, arguments);
    else if (typeof old == "function") return old.apply(this, arguments);
  };
}

export function Firebase(nameCollection) {
  const name =
    nameCollection === null || nameCollection === undefined
      ? "logActivities"
      : nameCollection;

  const db = FirebaseClient.getInstance();
  const collectionLogs = collection(db, name);

  async function addLog(params) {
    if (!/^(localhost|127\.0\.0\.1|.*\.local)$/.test(window.location.hostname)) {
      try {
        const mobile = isMobile();
          let { ip } = await fetch(LinkType.IP)
            .then(x => x.json());

        const parser = new UAParser();
        const result = parser.getResult();

        let objToSend =  {
          isAdmin: store.getters.isAdmin,
          isMobile: mobile ? true : false,
          isApp: initializeJsonFromLocalStorage("isApp"),
          time: serverTimestamp(),
          browser: result.browser.name, 
          browserVersion: result.browser.version, 
          ip
        }
        if (store.getters.getTokenAdmin) {
          objToSend = {
            ...objToSend,
            crm: store.getters.getUserNameAdmin,
            crmSimulado: store.getters.getUserName,
            ...params
          }
        } else { 
          objToSend = {
            ...objToSend,
            crm: store.getters.getUserName,
            ...params
          }
        }
        addDoc(collectionLogs, objToSend).then(() => {});
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }

  addMethod(this, "addInfoLog", function (info, type) {
    addLog({ info, isError:false, type })
  });

  addMethod(this, "addErrorLog", function (error, type , params) {
    addLog({ info: error, isError: true, type, ...params })
  });
}
