import axios from "../http-client/axios";
import { SLIPKNOP_URL } from "../urls/base-urls";

export async function addAssembly(formData, formFile) {
  let formRequest = new FormData();
  const formDataBlob = new Blob([JSON.stringify(formData)], {
    type: "application/json",
  });

  formRequest.append("model", formDataBlob);

  if (formFile.length > 0) {
    formFile.forEach((file) => formRequest.append("file", file));
  }

  const resp = await axios.post(`${SLIPKNOP_URL}/admin/assembly/create`, formRequest);

  return resp.data;
}


export async function getAssemblies(filter, page, size) {
  let query = `?page=${page}&size=${size}`;

  if(filter)
    query += `&filter=${filter}`

  const resp = await axios.get(`${SLIPKNOP_URL}/admin/assembly/list${query}`);
  return resp.data;
}


export async function deleteAssembly(id) {
  const resp = await axios.delete(`${SLIPKNOP_URL}/admin/assembly?id=${id}`);
  return resp.data;
}

export async function getAssemblyReport(id) {
  const resp = await axios.get(`${SLIPKNOP_URL}/admin/assembly/report-event?id=${id}`);
  return resp.data;
}



export async function getAssemblyParticipantsList(crms, page, size) {
  let query = `?crms=${crms}&page=${page}&size=${size}`
  const resp = await axios({
    url: `${SLIPKNOP_URL}/admin/assembly/report-participant-list${query}`,
    method: "get",
    headers: {
      "content-type": "application/json",
    },
  });
  return resp.data;
}


export async function downloadAssemblyParticipants(crms) {
  let query = `?crms=${crms}`
  const resp = await axios({
    url: `${SLIPKNOP_URL}/admin/assembly/report-participant${query}`,
    method: "get",
    headers: {
      "content-type": "application/json",
    },
  });
  return resp.data;
}

export async function updateAssembly(formData, formFile) {
  let formRequest = new FormData();
  const formDataBlob = new Blob([JSON.stringify(formData)], {
    type: "application/json",
  });

  formRequest.append("model", formDataBlob);

  if (formFile.length > 0) {
    formFile.forEach((file) => formRequest.append("file", file));
  }

  const resp = await axios.put(
    `${SLIPKNOP_URL}/admin/assembly`
    // "http://localhost:8300/assembly"
    , formRequest
  );

  return resp.data;
}

//  eslint-disable-next-line no-unused-vars
export async function getAssemblyById(id) {
  const resp = await axios({
    url: `${SLIPKNOP_URL}/admin/assembly?id=${id}`,
    // url: `http://localhost:8300/assembly?id=${id}`,
    method: "get",
    data: {},
    headers: { "content-type": "application/json" },
  });

  if (resp.data) {
    return mapperResponse(resp.data);  
  }
}

function mapperResponse(response) {
  
  response.participantsDB = response.participants;
  response.participants = null;
  return response;
}

export async function getAssemblyParticipation(filter, page, size) {
  let query = `?page=${page}&size=${size}`;

  if(filter)
    query += `&filter=${filter}`

  const resp = await axios.get(
    //`http://localhost:8300/assembly/list${query}`
     `${SLIPKNOP_URL}/assembly/list${query}`
  );
  return resp.data;
}