<template>
  <div class="config-padding">
    <b-card class="justify-content-center">
      <div>
        <div class="align-items-center pb-4">
          <div class="config-msg pb-3">
            MENSAGEM
          </div>
          <div class="custom-height-text-area">
            <b-form-textarea
              class="w-100 h-100"
              id="textarea"
              v-model="form.message"
              :state="validateField('message')"
              placeholder="Escreva uma descrição para sua solicitação…"
            ></b-form-textarea>
          </div>
        </div>
        <div>
          <UploadFile
            @updateFile="eventToUpdateFile"
            :multiple="false"
            :description="'Adicionar anexo'"
          />
        </div>
      </div>
    </b-card>
  </div>  
</template>

<script>
import { inject } from "@vue/composition-api";
import UploadFile from "../../../components/upload-file/UploadFile.vue";
import { requiredFieldsFilled } from "../../../utils/validate/validate.js";

const DefaultForm = {
  category: null,
  subCategory: null,
  manifestation: null,
  message: null,
  files: [],
};

export default {
  components: {
    UploadFile,
  },
  setup() {
    const formData = inject("formData");
    const setForm = inject("setForm");
    const setSubmitHandler = inject("setSubmitHandler");
    return { formData, setForm, setSubmitHandler };
  },

  async mounted() {
    this.initForm();
  },
  data() {
    return {
      protocol: "",
      formSubmited: false,
      form: { ...DefaultForm },
      validations: {},
      requiredFieldsFilled,
    };
  },
  methods: {
    validateField(name) {
      let value = this.form[name];

      let validation = !!value;
      this.validations[name] = validation;
      if (!this.formSubmited) return null;
      return !!value;
    },
    eventToUpdateFile(files) {
      this.form.files = files;
    },
    async initForm() {
      this.setSubmitHandler(this.onSubmit);
      this.formData = {
        form: { ...this.form },
        onSubmitHandler: this.onSubmit,
      };
    },
    onSubmit() {
      this.setForm(this.form);
      this.formSubmited = true;
      if (this.requiredFieldsFilled()) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.config-msg {
  color: var(--grey-1);
  font-size: multiplier-default-font-size(1); // 12
}
.config-padding {
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.custom-height-text-area {
  height: 11.5rem;
}
</style>
