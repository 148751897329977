<template>
  <div class="page-container">
    <div>
      <section class="page-header ">
        <h2 class="font-weight-bold">
          Cadastro
        </h2>
      </section>

      <div class="separator-line"></div>
      <section class="sub-header">
        <p class="p3">
          Manter seu cadastro atualizado faz parte das Regras Regimentais da UGF
        </p>
        <p>
          Seção III - Deveres dos Cooperados - e para que possamos evitar
          possíveis impactos no pagamento da sua produção e demais questões que
          envolvem o(a) doutor(a) como nosso(a) cooperado(a), contamos com o seu
          apoio para realizar as atualizações sempre que necessário.
        </p>
        <p class="p3">
          <a href="https://forms.gle/6p26nmXd4BNVU4yS8" target="_blank"
            >Clique aqui para solicitar a exclusão de dados da conta e cancelar
            o acesso à plataforma
          </a>
        </p>
      </section>
    </div>
    <section>
      <div class="card-container">
        <CustomInfoCard
          class="deck deck-margin"
          v-for="card in getCards"
          :key="card.id"
          :onCardClick="() => onClickCard(card.route)"
          :headerIcon="card.headerIcon"
          :title="card.header"
          :description="card.description"
          :icon="card.icon"
          :featureName="card.featureName"
        />
      </div>
    </section>
  </div>
</template>

<script>
import CustomInfoCard from "../../../components/custom-info-card/CustomInfoCard.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

export default {
  components: {
    CustomInfoCard,
  },
  computed: {
    getCards: () => [
      {
        id: 0,
        icon: "smart-card-outline.svg",
        header: "Informações pessoais",
        description: "Informações gerais, endereços e contatos.",
        route: RoutesPaths.register.personalInformation(),
        featureName: "REGISTER_CARD_PERSONAL_INFORMATION",
      },
      {
        id: 1,
        icon: "doctor.svg",
        header: "Especialidades médicas",
        description: "Especialidades cadastradas, tipo e área de atuação.",
        route: RoutesPaths.register.medicalSpecialities(),
      },
      {
        id: 2,
        icon: "map-marker-outline.svg",
        header: "Locais de atendimento",
        description:
          "Informe os locais onde são realizados os seus atendimentos.",
        route: RoutesPaths.register.attendencePlace(),
      },
      {
        id: 3,
        icon: "attach_money.svg",
        header: "Meios de pagamentos",
        description: "Informe meios de pagamentos.",
        route: RoutesPaths.register.paymentOptions(),
      },
      {
        id: 4,
        icon: "script-text-outline.svg",
        header: "Impostos e alvará",
        description: "Consulte e cadastre opções de impostos e alvará.",
        route: RoutesPaths.register.taxLicense(),
      },
      {
        id: 5,
        icon: "MDI-calendar-check.svg",
        header: "Participação Assembleia",
        description: "Acompanhe o histórico de participação nas assembleias e reuniões da UGF",
        route: RoutesPaths.contentManagement.assemblyParticipation(),
      },
    ],
  },
  methods: {
    onClickCard(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.deck-margin {
  margin: 2rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page-header {
  text-align: center;
}

.sub-header {
  max-width: 83rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  text-align: center;
}

.card-container {
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(xs) {
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
  }
}
</style>
