<template>
  <div class="">
    <p class="p1 custom-title" v-text="this.productionTransfer.descriptionProduction"></p>
    <div class="imagem-analytics">
      <div class="flex-container">
        <CustomLargeCard
          :amountReceivable="productionTransfer.mainData.amountReceivable"
          :discount="productionTransfer.mainData.discount"
          :grossValue="productionTransfer.mainData.grossValue"
          :invoiceValue="productionTransfer.mainData.invoiceValue"
          :techCash="getValue(productionTransfer.techCash)"
          icon="icon_analitica.svg"
          class="mt-5"
        />

        <div class="d-flex flex-column mt-5">
          <CustomCard
            :amountReceivable="this.productionTransfer.backBeat"
            title="BACKBEAT"
            :onDetailClick="onBackbeatDetailClick"
            :isShowLink="true"
          />
          <CustomCard
            :amountReceivable="productionTransfer.grosses"
            title="GLOSAS"
            :onDetailClick="onGlossesDetailClick"
            :isShowLink="this.$props.isShowLink"
            class="margin"
          />
        </div>
      </div>
    </div>

    <div class="span my-5"></div>
  </div>
</template>

<script>
import CustomLargeCard from "../modal/CustomLargeCard.vue";
import CustomCard from "../modal/CustomCard.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import { showBackbeat } from '../../../../services/backbeat/route-page';

export default {
  props: ["transfer", "year", "month", "isShowLink"],
  data() {
    return {
      productionTransfer: this.$props.transfer,
    };
  },
  components: {
    CustomLargeCard,
    CustomCard,
  },
  methods: {
    onBackbeatDetailClick() {
      showBackbeat(this.$props.year, this.$props.month);
    },
    getValue(value) {
      return value == null ? "0" : value;
    },
    onGlossesDetailClick() {
      this.$router.push({
        path: RoutesPaths.financial.reviewGlosses(),
        query: { year: this.$props.year, month: this.$props.month },
        replace: true,
      });
    },
  },
};
</script>

<style scoped>
.margin {
  margin-top: 2.2rem;
}

.span {
  display: flex;
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--light-4);
}

.imagem-analytics {
  background-image: url("../../../../assets/images/ilus_analitica.svg");
  background-repeat: no-repeat;
  background-position: 85% 90%;
}

.flex-container {
  max-width: 70rem;
  display: grid;
  border-style: none;
  grid-column: 0;
  justify-content: center;
  align-items: center;
}

@media (min-width: 725px) {
  .flex-container {
    grid-template-columns: auto 300px;
    justify-content: initial;
    align-items: initial;
  }
}
.custom-title {
  text-align: left;
}

@media (max-width: 577px) {
  .custom-title {
    text-align: center !important;
  } 
}
</style>
