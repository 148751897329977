<template>
  <b-modal :id="lModalId" ref="attention-modal" ok-only>
    <b-container>
      <b-row class="justify-content-center">
        <div class="attention-container">
          <div class="attention-container--outter-circle">
            <div class="attention-container--inner-circle">
              <i class="attention-container--icon fas fa-exclamation"></i>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="justify-content-center mt-5">
        <h2>
          {{ $props.title }}
        </h2>
      </b-row>
      <b-row class="mt-3">
        <slot name="description"> </slot>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-button v-if="$props.cancelButtonText"  @click="onActioHandler(false)" variant="outline-primary">
        {{ $props.cancelButtonText ? $props.cancelButtonText : "Voltar" }}
      </b-button>

      <b-button @click="onActioHandler(true)" class="ml-4" variant="primary">
        {{ $props.submitButtonText ? $props.submitButtonText : "Confirmar" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: [
    "title",
    "modalId",
    "description",
    "buttonText",
    "cancelButtonText",
    "submitButtonText",
    "onAction",
  ],
  setup(props, ctx) {
    const lModalId = props.modalId ? props.modalId : "attention-modal";

    const closeModal = function() {
      ctx.refs["attention-modal"].hide();
    };

    const onActioHandler = function(event) {
      props.onAction(event);
      closeModal();
    };

    return { lModalId, onActioHandler };
  },
};
</script>

<style lang="scss" scoped>
.attention-container {
  &--icon {
    font-size: 4rem;
    color: white;
  }

  &--inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    background: var(--yellow-1);
    border-radius: 100%;
  }

  &--outter-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    width: 9rem;
    background: var(--yellow-0);
    border-radius: 100%;
  }
}
</style>
