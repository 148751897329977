<template>
  <div>
    <CustomModalTemplate
      modalId="accept-modal"
      title="Termos de aceite"
      ref="form-modal"
      noCloseOnBackdrop
    >
      <template slot="header"><h4>Termos de aceite</h4></template>

      <template slot="content">
        <b-form id="alter-form" class="alter-form" onSubmit="{this.onSubmit}">
          <p class="p4 mt-2">
            <i class="fas fa-info-circle"></i>
            Olá Dr(a). {{ nickname }} Seja bem-vindo(a)!
          </p>
          <p class="p4 ml-4">
            Esse é o seu primeiro acesso, por isso pedimos que você leia os
            termos e aceite as boas práticas e informações que apresentamos.
          </p>
          <div id="box-termo" class="box-termo">
            <h6 class="mt-4">TERMO DE ADESÃO AO SISTEMA DE TELEMEDICINA</h6>
            <h6 class="mt-4">
              <span class="detach">{{ name }}</span
              >, <span class="detach"> {{ nationality }}</span
              >, <span class="detach">{{ marital_status }}</span
              >, médico(a) inscrito(a) no CRM/SC sob n°.
              <span class="detach">{{ crm }}</span> e no CPF/MF sob o nº.
              <span class="detach">{{ cpf }}</span> com Registro de Qualificação
              de Especialista (RQE) nº <span class="detach">{{ rqe }}</span
              >, na especialidade de <span class="detach">{{ specialty }}</span
              >, e-mail <span class="detach">{{ email }}</span>
              residente e domiciliado(a)na
              <span class="detach">{{ street }}</span> nº.
              <span class="detach">{{ number }}</span
              >, bairro <span class="detach">{{ neighborhood }}</span
              >, CEP <span class="detach">{{ cep }}</span
              >, na Cidade de <span class="detach">{{ city }}</span
              >, Estado de <span class="detach">Santa Catarina</span>; na
              qualidade profissional de saúde e cooperado da UNIMED GRANDE
              FLORIANÓPOLIS – COOPERATIVA DE TRABALHO MÉDICO(“UGF”), pessoa
              jurídica de direito privado, com sede na Rua Dom Jaime Câmara, nº.
              94, Centro, na Cidade de Florianópolis, Estado de Santa Catarina,
              inscrita no CNPJ/MF sob nº. 77.858.611/0001-08 e registrada
              perante a Agência Nacional de Saúde (ANS) sob o nº. 36044-9;
            </h6>

            <h6 class="mt-4">
              CONSIDERANDO a existência da Resolução nº. 2.314/2022, do Conselho
              Federal de Medicina (“CFM”), que define e regulamenta a
              telemedicina como forma de serviços médicos mediados por
              tecnologias de comunicação;
            </h6>

            <h6 class="mt-4">
              CONSIDERANDO QUE a UGF disponibiliza, a seu livre critério,
              Sistema que viabiliza a atuação profissional dos seus médicos
              cooperados por Telemedicina, na modalidade de Teleconsulta,
              mediada por Tecnologias Digitais, de Informação e de Comunicação
              (TDICs);
            </h6>

            <h6 class="mt-4">
              CONSIDERANDO QUE os médicos cooperados podem utilizar o Sistema
              disponibilizado pela UGF após o devido aceite do presente Termo de
              Adesão ao Sistema de Telemedicina, todos os dias da semana, ou
              seja, 24h (vinte e quatro horas) por dia, 7 (sete) dias por
              semana, incluindo feriados;
            </h6>

            <h6 class="mt-4">
              CONSIDERANDO QUE os médicos cooperados da UGF, ao aceitarem atuar
              profissionamente através da Telemedicina, serão remunerados de
              acordo com os normativos da UGF, nos atendimentos aos
              beneficiários da UGF, e no valor determinado pela Unimed do
              Brasil, nos atendimentos aos beneficiários de intercâmbio;
            </h6>

            <h6 class="mt-4">
              CONSIDERANDO QUE a Lei nº. 14.510, de 27 de dezembro de 2022,
              autorizou e disciplinou a prática da telessaúde em todo território
              nacional, modalidade de prestação de serviços de saúde a
              distância, por meio da utilização das tecnologias da informação e
              da comunicação, que envolve, entre outros, a transmissão segura de
              dados e informações de saúde, por meio de textos, de sons, de
              imagens ou outras formas adequadas;
            </h6>

            <h6 class="mt-4">O médico cooperado acima identificado DECLARA:</h6>

            <h6 class="mt-4">
              1. Que tem ciência das normas legais aplicáveis ao exercício da
              profissão médica e, por tal motivo, opta livremente exercê-la
              através da Telemedicina, que ocorrerá, exclusivamente, por
              intermédio do Sistema disponibilizado pela UGF;
            </h6>

            <h6 class="mt-4">
              2. Que teve o devido treinamento da UGF para a correta utilização
              do Sistema, sendo-lhe oportunizado sanar todas as dúvidas;
            </h6>

            <h6 class="mt-4">
              3. Que seguirá e cumprirá todas as normas e orientações da UGF
              quanto ao uso do Sistema e postura profissional durante os
              atendimentos de Telemedicina realizados nos pacientes,
              comprometendo-se, inclusive, a zelar pela imagem, marca e
              patrimônio moral e material da Cooperativa;
            </h6>

            <h6 class="mt-4">
              4. Que ACEITA prestar serviços médicos de Telemedicina aos
              beneficiários do Sistema Cooperativo Unimed com cobertura para
              Teleconsulta, incluídos os beneficiários da UGF, em conformidade
              com as disposições legais, do Código de Ética Médica, da Resolução
              CFM n° 2.314/2022, dos normativos da Cooperativa, ou seja,
              Estatuto Social, Regimento Interno, Código de Conduta, Políticas
              de Compliance, Política de Segurança da Informação e Privacidade
              de Dados Pessoais, Consensos das Especialidades Médicas,
              Deliberações da Assembleia Geral, das Resoluções, Deliberações e
              Diretrizes dos Órgãos de Administração, bem como as demais normas
              cooperativas vigentes, inclusive aquelas aprovadas e aplicadas no
              âmbito do Sistema Cooperativo Unimed;
            </h6>

            <h6 class="mt-4">
              5. Que antes do aceite do presente Termo de Adesão lhe foram
              asseguradas a liberdade e a completa independência de decidir
              sobre a utilização ou não da Telemedicina, inclusive com relação à
              primeira consulta, sendo-lhe informada a possibilidade de indicar
              a utilização de atendimento presencial ao paciente ou optar por
              ele, sempre que entender necessário;
            </h6>

            <h6 class="mt-4">
              6. Que durante o exercício da Telemedicina adotará rígidos
              parâmetros éticos, técnicos e legais, garantindo o mesmo padrão de
              qualidade assistencial que o adotado para o atendimento
              presencial;
            </h6>

            <h6 class="mt-4">
              7. Que é responsável, única e exclusivamente, pelas informações
              inseridas no Sistema, e, como tal, compromete-se a fornecer e/ou
              disponibilizar à UGF: (i) informações verdadeiras, certas, exatas,
              completas e atualizadas, e/ou; (ii) qualquer informação e/ou
              documento dos atendimentos realizados através da Telemedicina e do
              Sistema disponibilizado;
            </h6>

            <h6 class="mt-4">
              8. Que visando a melhoria contínua do Sistema responderá
              prontamente às pesquisas de satisfação e informará à UGF acerca de
              quaisquer falhas apresentadas no Sistema;
            </h6>

            <h6 class="mt-4">
              9. Que ao prestar serviços por Telemedicina, através do Sistema
              disponibilizado pela UGF, sempre conferirá os dados do paciente
              (nome completo e data de nascimento) no início do atendimento e
              informará ao paciente, previamente, todas as limitações inerentes
              ao uso da Telemedicina, tendo em vista a impossibilidade de
              realização de exame físico completo durante a consulta;
            </h6>

            <h6 class="mt-4">
              10. Que ao se valer da Telemedicina manterá registro detalhado dos
              atendimentos e dos diagnósticos fornecidos, bem como das
              informações recebidas e nas quais os diagnósticos foram baseados,
              assumindo, única e exclusivamente, toda e qualquer
              responsabilidade pelos diagnósticos e tratamentos recomendados aos
              pacientes atendidos;
            </h6>

            <h6 class="mt-4">
              11. Que nos termos do § 1°, artigo 3º, da Resolução CFM nº.
              2.314/2022, do Conselho Federal de Medicina (CFM), registrará os
              atendimentos de Telemedicina do paciente em prontuário médico
              eletrônico, atendendo aos padrões de representação, terminologia e
              interoperabilidade, contemplando, obrigatoriamente: (i) os dados
              clínicos necessários para a boa condução do caso, sendo preenchido
              em cada contato com o paciente; (ii) a data, hora, tecnologia da
              informação e comunicação utilizada para o atendimento; (iii) o
              número de registro no Conselho Regional Profissional e sua unidade
              da federação;
            </h6>

            <h6 class="mt-4">
              12. Que seguirá todos os normativos legais quanto à transmissão de
              receituários e atestados médicos, sendo estes assinados por meio
              de assinatura digital qualificada, padrão Infraestrutura de Chaves
              Públicas Brasileira - ICP-Brasil, nos termos da legislação vigente
              no país;
            </h6>

            <h6 class="mt-4">
              13. Que se compromete a cumprir e fazer cumprir as normas vigentes
              e futuras que disciplinem a Telemedicina, sejam estas legais,
              administrativas ou regulatórias, sempre observando as
              recomendações da UGF;
            </h6>

            <h6 class="mt-4">
              14. Que é o único responsável por seu acesso ao Sistema
              disponibilizado pela UGF e uso indevido do seu login;
            </h6>

            <h6 class="mt-4">
              15. Que está ciente e concorda que poderá ser afastado(a)
              preventivamente da prestação de serviços por Telemedicina
              disponibilizada pela UGF, imediatamente após o recebimento de
              notificação extrajudicial enviada pela UGF, nas seguintes
              hipóteses: (i) descumprimento de quaisquer disposições previstas
              neste Termo de Adesão à Telemedicina, não sanadas no prazo de 48h
              (quarenta e oito horas) úteis, após o recebimento de notificação
              extrajudicial da UGF; (ii) existência de, no mínimo, 5 (cinco)
              reclamações formais junto à UGF, mesmo que não apuradas ou em fase
              de apuração através de sindicância ou processo administrativo
              disciplinar, com indício(s) de: (ii.1) dano à imagem ou marca
              Unimed; (ii.2) quebra de sigilo profissional; (ii.3) tratamento
              desrespeitoso, discriminatório e/ou preconceituoso que afete a
              honra e a dignidade do paciente, relacionados à sua etnia, raça,
              cor, credo, religião, formação, gênero, orientação sexual, idade,
              escolaridade, aparência, doença, limitação física e/ou mental,
              nacionalidade, situação socioeconômica, opção partidária e/ou
              ideologia política; (ii.4) utilização de expressões, gestos,
              comentários, insinuações, termos pejorativos ou de cunho sexual
              que sejam inadequados, hostis, incompatíveis, intimidadores,
              ameaçadores e/ou desnecessários à realização da Teleconsulta.
            </h6>

            <h6 class="mt-4">Florianópolis, {{ today }}.</h6>
            <h6>{{ name }}</h6>
            <h6>CRM/SC {{ crm }}</h6>
          </div>
        </b-form>

        <b-card border-variant="light">
          <b-form-checkbox
            id="acordoCheckbox"
            v-model="status"
            name="acordoCheckbox"
            value="1"
            unchecked-value="0"
            size="lg"
          >
            <span class="accept">Li e estou de acordo</span>
          </b-form-checkbox>
        </b-card>
      </template>

      <template slot="footer">
        <b-button
          variant="outline-primary"
          class="ml-4"
          @click="$bvModal.hide('accept-modal')"
          :disabled="this.loading"
        >
          Fechar
        </b-button>
        <b-button
          form="alter-form"
          type="button"
          class="ml-4"
          variant="primary"
          @click="onSubmit()"
          :disabled="status == 0 || this.loading || this.$store.getters.isAdmin"
        >
          Ir para o site
          <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
        </b-button>
      </template>
    </CustomModalTemplate>
  </div>
</template>

<script>
import { addOtherSolicitation } from "../../../../services/other-solicitation/other-solicitation";
import CustomModalTemplate from "../../../../components/custom-modal/CustomModalTemplate.vue";
import { formatDate } from "../../../../utils/date/date-utils.js";
import {
  getLinkTelemedicina,
  getUserInformation,
} from "../../../../services/virtual-office/VirtualOfficeServices.js";
import html2pdf from "html2pdf.js";
import { Firebase } from "../../../../services/firebase/log-firebase";

export default {
  components: { CustomModalTemplate },
  async mounted() {
    this.initLog();
    const crm = this.$store.getters.getUserName;
    
    this.firebase.addInfoLog(
      `CRM ${crm} abriu o modal do termo`,
      "VIRTUAL_OFFICE"
    );

    this.setUserInformation(crm);
    
    this.firebase.addInfoLog(
      `Informacoes do ${crm} obtidas`,
      "VIRTUAL_OFFICE"
    );
  },
  data() {
    return {
      name: this.$store.getters.getUserInformation?.name,
      crm: this.$store.getters.getUserInformation?.crm,
      nationality: "------------",
      marital_status: this.$store.getters.getUserInformation?.maritalStatus,
      cpf: this.$store.getters.getUserInformation?.cpf,
      specialty: "------------",
      street: "------------",
      number: "------------",
      city: "------------",
      cep: "------------",
      nickname: this.$store.getters.getUserInformation?.nickname,
      status: 0,
      today: formatDate(new Date()),
      rqe: "",
      neighborhood: "",
      email: "",
      loading: false,
    };
  },
  methods: {
    initLog() {
      this.firebase = new Firebase();
    },
    onSubmit() {
      this.getTelemedicine(this.$store.getters.getUserName, true);
    },
    async setUserInformation(crm) {
      const response = await getUserInformation(crm);
      this.cpf = response.cpf;
      this.marital_status = response.marital_status;
      this.name = response.name;
      this.nationality = response.nationality;
      this.specialty = response.specialty;
      this.street = response.street;
      this.number = response.number;
      this.city = response.city;
      (this.cep = response.cep), (this.rqe = response.rqe);
      this.neighborhood = response.neighborhood;
      this.email = response.email;
    },
    async getTelemedicine(crm, valor) {
      this.loading = true;
      try {
        this.firebase.addInfoLog(
          `CRM ${crm} abriu clicou para aceitar o termo`,
          "VIRTUAL_OFFICE"
        );
        const response = await getLinkTelemedicina(crm, valor);
        if (response) {
          await this.sendSolicitacao();
          this.$refs["form-modal"].$children[0].hide();
          const aElement = document.createElement("a");

          if (this.$store.getters.isApp) {
            aElement.href = response.replace("https", "http");
          } else {
            aElement.href = response;
          }
          this.loading = false;

          aElement.setAttribute("target", "_blank");
          aElement.click();
        }
      } catch (error) {
        this.firebase = new Firebase();
        this.firebase.addErrorLog(
              `Ocorreu um erro quando o CRM ${crm} tentou clicou em aceitar o termo: ` + error,
              "VIRTUAL_OFFICE"
            );
        this.loading = false;
        console.error("getTelemedicine", error);
      }
    },
    async sendSolicitacao() {
      const file = await this.createPDF();
      let files = Array.of(file);

      const formData = {
        category: 264,
        subCategory: 338,
        manifestation: 715,
        message:
          "O cooperado acessou a primeira vez a telemedicina e aceitou o termo, por favor incluir a DOC24 como local de atendimento deste prestador.",
      };
      this.protocol = await addOtherSolicitation(formData, files);
    },
    async createPDF() {
      return new Promise((resolve, reject) => {
        var element = document.getElementById("box-termo");

        var clonedElement = element.cloneNode(true);
        clonedElement.style.maxHeight = "none";

        let opt = {
          margin: 1,
          filename: "termo-de-uso.pdf",
          image: { type: "jpeg", quality: 0.95 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        };

        try {
          const blob = html2pdf()
            .set(opt)
            .from(clonedElement)
            .outputPdf("blob", "termo-de-uso.pdf");

          clonedElement.remove();
          resolve(blob);
        } catch (e) {
          clonedElement.remove();
          reject(e);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detach {
  font-weight: bold;
  text-transform: uppercase;
}

.box-termo {
  padding: 1rem;
  max-height: 40rem;
  min-height: 6rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.accept {
  margin-top: 3rem;
  font-size: 1.6rem;
}

.input-file-none {
  display: none;
}

.input-file-block {
  display: block;
}
</style>
