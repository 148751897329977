import initializeJsonFromLocalStorage from "../../../utils/storage/initialize-storage-utils";
import { getAnalyticsAfterLogin } from "../../../services/analytics/analytics-services.js";

const analyticalData = {
  state: {
    analyticalData: initializeJsonFromLocalStorage("analytical-data"),
    analyticalDataLoading: initializeJsonFromLocalStorage("analytical-data-loading"),
  },
  mutations: {
    setAnalyticalData(state, data) {
      state.analyticalData = data;
    },
    setAnalyticalDataLoading(state, data) {
      state.analyticalDataLoading = data;
    },
    clear(state) {
      state.analyticalData = null;
      state.analyticalDataLoading = null;
    },
  },
  actions: {
    requestAnalyticalData({ commit }, crm) {
                
      analyticalDataLoading(commit, true);   

      return new Promise((resolve, reject) => {
        getAnalyticsAfterLogin(crm)
          .then((analytical) => {
            localStorage.setItem("analytical-data", JSON.stringify(analytical));
            commit("setAnalyticalData", analytical);
            resolve(analytical);

            analyticalDataLoading(commit, false);
          })
          .catch((err) => {            
            console.error("requestLogin error: ", err);            
            analyticalDataLoading(commit, false);
            reject(err);
          });
      });
    },
    setStoreAnalyticalData({ commit }, data) {
      localStorage.setItem("analytical-data", JSON.stringify(data));
      commit("setAnalyticalData", data);
    },
    clearAnalyticalData({ commit }) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem("analytical-data", "");
          commit("clear");
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    },
  },
  getters: {
    getAnalyticalData: (state) => state.analyticalData,
    getAnalyticalDataLoading: (state) => state.analyticalDataLoading,
  },
};
 
function analyticalDataLoading(commit, isLoading){
  var analyticalDataLoading = {analyticalDataLoading: isLoading};
  localStorage.setItem("analytical-data-loading", JSON.stringify(analyticalDataLoading));
  commit("setAnalyticalDataLoading", analyticalDataLoading); 
}

export default analyticalData;
