<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <b-col class="pl-0 pr-0">
          <b-row class="mt-4 mb-4" align-h="between">
            <b-col class="pl-0 pr-0" sm="4">
              <b-form-input
                id="input-1"
                class="text-left"
                v-model="filterTitle"
                type="text"
                placeholder="Busque por nome ou data…"
              ></b-form-input>
            </b-col>
            <b-col class="pl-0 pr-0" sm="4"></b-col>
          </b-row>
        </b-col>
      </template>
      <template slot="table">
        <div class="scrollme">
          <b-table
            class="table-custom-assembly spaced-text"
            :fields="fields"
            :items="items"
            v-show="!this.loading"
            show-empty
            empty-text="Nenhuma informação para exibir!"
          >
            <template #cell(isParticipant)="data">
              <div class=" d-flex justify-content-between w-100" 
                :class="getClassCustom(data.item)"></div>
            </template>
          </b-table>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <div
          class="d-flex bd-highlight mb-3 justify-content-between"
          :class="isMobile() ? 'flex-column-reverse' : 'flex-row'"
          style="width: 100%;"
        >
          <div
            class="mx-auto"
            :class="isMobile() ? '' : 'pagination-assembly align-self-center'"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="perPage"
              aria-controls="my-table"
              size="lg"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
  </div>
</template>

<script>
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { resolve_path_url } from "../../../utils/image/image-utils";
import { isMobile } from "../../../utils/mobile/mobile";

import { getAssemblyParticipation } from "../../../services/assembly-management/assembly-management-service";

export default {
  components: {
    TableVisualizationLayout,
  },
  mounted() {
    this.getAssemblys();
  },
  watch: {
    page: {
      handler() {
        this.getAssemblys();
      },
    },
    filterTypeId: {
      handler() {
        this.getAssemblys();
      },
    },
    filterTitle: {
      handler() {
        if (this.filterTitle.length > 2 || this.filterTitle.length == 0) {
          this.getAssemblys(); 
        }
      },
    },
  },
  data() {
    return {
      filterTitle: "",
      page: 1,
      perPage: 10,
      totalSize: 10,
      itemToDelete: {},
      itemToUpdateStatus: {},
      statusUpdate: null,
      textModalUpdateStatus: null,
      textConfirm: null,
      msgSuccess: "",
      leftAction: {
        text: "Participação Assembleia",
        onClick: this.leftActionClickHandler,
      },
      leftInfo:
        "Acompanhe o histórico de participação nas assembleias e reuniões da UGF.",
      fields: [
        {
          key: "event",
          label: "Evento",
          thStyle: { width: "70%" },
        },
        {
          key: "eventDate",
          label: "Data",
          thStyle: { width: "20%" },
        },
        {
          key: "isParticipant",
          label: "Participação",
          thStyle: { width: "10%" },
        },
      ],
      items: [],
      loading: true,
      resolve_path_url,
    };
  },
  methods: {
    isMobile: isMobile,
    async getAssemblys() {
      try {
        this.loading = true;
        const assemblysResponse = await getAssemblyParticipation(
          this.filterTitle,
          this.page - 1,
          this.perPage
        );
        console.log("assemblysResponse => ", assemblysResponse)

        this.items = assemblysResponse.items;
        this.totalSize = assemblysResponse.totalItems;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error("getAssemblys", error);
      }
    },
    leftActionClickHandler() {
      this.$router.go(-1);
    },
    getClassCustom(item) {
      return item?.isParticipant == true ? " custom-icon-partipation " : " custom-icon-not-partipation ";
    }
  },
};
</script>
<style lang="scss">
div.table-custom-assembly td {
  color: #7a7e83;
  padding-left: 2rem;
}
div.table-custom-assembly th {
  padding-left: 2rem;
}
</style>
<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}
.itemleft {
  position: absolute;
  left: 0;
}
.confirm-text {
  margin: auto;
}
.custom-actions {
  width: 90%;
}
.pagination-assembly ul {
  margin-bottom: 0rem;
}
.scrollme {
  overflow-x: auto;
}
.custom-icon-partipation {
  background-image: url("../../../assets/icons/MDI-calendar-check-participation.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-position: center;
}

.custom-icon-not-partipation {
  background-image: url("../../../assets/icons/MDI-calendar-remove.svg");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  border-radius: 30px;
  background-position: center;
}
</style>
