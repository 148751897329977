<template>
  <div id="div-period" v-if="$props.item">
    <div class="container">
      <div class="align-items-center period-desc T16">
        <div>
          <img
            class="custom-img"
            :src="resolve_img_url('MDI-calendar-clock.svg')"
          />
        </div>
        <div class="pl-1 custom-desc-period align-items-center">
          {{ getDescriptionPeriod() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resolve_img_url from "../../../utils/image/image-utils";

export default {
  props: ["item"],
  components: {},
  data() {
    return {};
  },
  methods: {
    resolve_img_url: resolve_img_url,
    getDescriptionPeriod() {
      const training = this.$props.item;
      if (training) {
        if (
          training.user?.dtStart == null ||
          training.user?.dtFinished == null
        ) {
          const desctEnd =
            training.dtEnd != null ? "Até " + training.dtEnd : "Contínuo";
          return "Prazo para realização: " + desctEnd;
        } else {
          return (
            "Realizado em: " +
            training.user.dtStart +
            " à " +
            training.user.dtFinished
          );
        }
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.period-desc {
  display: flex;
  flex-flow: row wrap;
  margin-left: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.custom-img {
  height: 2.2rem;
  width: 2.2rem;
}

hr {
  border-color: #aaaaaa36;
  box-sizing: border-box;
  width: 100%;
  margin: 0px 0 0 0;
}

.custom-desc-period {
  color: var(--green-2);
  font-size: 1.5rem;
}
</style>
