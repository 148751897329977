<template>
  <div>
    <div
      class="invalid"
      v-show="
        !checkVisibleCodeError([
          'LOGIN_MAX_ATTEMPT_FAILURE',
          'LOGIN_ALERT_ATTEMPT_FAILURE',
          'LOGIN_MULTIPLE_ATTEMPT_FAILURE',
          'LOGIN_ACCOUNT_LOCKED',
        ])
      "
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      {{ $props.msgErroInvalid }}
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_MAX_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      {{ $props.msgErroInvalid }}
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_ACCOUNT_LOCKED'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      Por questões de segurança, sua conta foi bloqueada!<br />
      Para reativá-la, por favor, redefina sua senha.<br />
      Isso ajudará a proteger sua conta e garantir seu acesso.
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_ALERT_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      <b>Considere redefinir sua senha!</b><br />
      Após mais uma tentativa incorreta, sua conta será bloqueada
      temporariamente.
    </div>
    <div
      class="invalid"
      v-show="checkVisibleCodeError(['LOGIN_MULTIPLE_ATTEMPT_FAILURE'])"
    >
      <i class="fas fa-exclamation-circle invalid"></i>
      CRM e/ou senha inválido(s).<br />
      <b>Considere redefinir sua senha!</b><br />
      Múltiplas tentativas incorretas podem resultar no bloqueio temporário de
      sua conta.
    </div>
    <div v-show="checkVisibleCodeError(['USER_NOT_FOUND'])">
      <p class="mt-3 custom-msg">
        Para o primeiro acesso ao Portal,
        <b-link class="custom-msg" @click="urlPassordForgot()">clique aqui</b-link>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["msgErroInvalid", "codeErro"],
  data() {
    return {};
  },
  methods: {
    checkVisibleCodeError(codeErros) {
      return codeErros.includes(this.codeErro);
    },
    urlPassordForgot() {
      this.$router.push("/password-recovery");
    },
  },
};
</script>

<style lang="scss" scoped>
.invalid {
  color: #dc3545;
  margin-top: 0.8rem;
}
.custom-msg {
  color: #00995D;
  font-size: 1.4rem;
}
</style>
