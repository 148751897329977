<template>
  <div>
    <p class="p1 custom-title" v-text="this.description"></p>
    <div class="imagem-analytics">
      <div class="flex-container">
        <CustomLargeCard
          :amountReceivable="getValue(physical.mainData.amountReceivable)"
          :discountonGlossesDetailClick="getValue(physical.mainData.discount)"
          :grossValue="getValue(physical.mainData.grossValue)"
          :invoiceValue="getValue(physical.mainData.invoiceValue)"
          :discount="getValue(physical.mainData.discount)"
          :techCash="getValue(physical.techCash)"
          icon="icon_analitica.svg"
          class="mt-5"
        />

        <div class="d-flex flex-column mt-5">
          <CustomCard
            :amountReceivable="this.physical.backBeat"
            title="BACKBEAT"
            :isShowLink="true"
            :onDetailClick="onBackbeatDetailClick"
            :type="'backbeat'"
            :tooltipText="'Ir para página do Backbeat'"
          />
          <CustomCard
            :amountReceivable="physical.grosses"
            title="GLOSAS"
            :onDetailClick="onGlossesDetailClick"
            :isShowLink="this.$props.isShowLink"
            :type="'gloses'"
            :tooltipText="'Ir para página de Recurso de Glosas'"
            class="margin"
          />
        </div>
      </div>
    </div>

    <div class="span my-5"></div>

    <div class="d-flex flex-wrap">
      <CustomMiddleCard
        class="mb-5"
        :amountReceivable="getValue(physical.talk.amountReceivable)"
        :grossValue="getValue(physical.talk.grossValue)"
        :invoiceValue="getValue(physical.talk.invoiceValue)"
        title="PALESTRAS"
        v-if="physical.talk.amountReceivable"
      />
      <div v-for="(meeting, index) in physical.meetings" :key="index">
        <CustomMiddleCard
          class="mb-5"
          :amountReceivable="getValue(meeting.amountReceivable)"
          :grossValue="getValue(meeting.grossValue)"
          :invoiceValue="getValue(meeting.invoiceValue)"
          title="REUNIÕES"
          v-if="meeting.amountReceivable"
        />
      </div>

      <CustomMiddleCard
        class="mb-5"
        :amountReceivable="
          getValue(physical.honorariumDirectors.amountReceivable)
        "
        :grossValue="getValue(physical.honorariumDirectors.grossValue)"
        :invoiceValue="getValue(physical.honorariumDirectors.invoiceValue)"
        title="HONORÁRIOS DIRETORIA"
        v-if="physical.honorariumDirectors.amountReceivable"
      />
    </div>

    <h4 class="custom-title" v-if="physical.otherValues.length > 0">Outros Valores</h4>

    <div class="d-flex body">
      <CustomMiddleCard
        v-for="(file, index) in physical.otherValues"
        class="my-5"
        :key="index"
        :amountReceivable="getValue(file.amountReceivable)"
        :invoiceValue="getValue(file.invoiceValue)"
        :grossValue="getValue(file.grossValue)"
      />
    </div>
  </div>
</template>

<script>
import CustomLargeCard from "../modal/CustomLargeCard.vue";
import CustomMiddleCard from "../modal/CustomMiddleCard.vue";
import CustomCard from "../modal/CustomCard.vue";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import { showBackbeat } from "../../../../services/backbeat/route-page";
export default {
  props: ["physicalPerson", "year", "month", "isShowLink"],
  mounted() {
    this.initDescription();
  },
  data() {
    return {
      physical: this.$props.physicalPerson,
      name: this.$store.getters.getUserInformation?.name,
      crm: this.$store.getters.getUserInformation?.crm,
      description: null,
    };
  },
  methods: {
    initDescription() {
      this.description = this.crm + " - " + this.name;
    },
    getValue(value) {
      return value == null ? "0" : value;
    },
    onBackbeatDetailClick() {
      showBackbeat(this.$props.year, this.$props.month);
    },
    onGlossesDetailClick() {
      this.$router.push({
        path: RoutesPaths.financial.reviewGlosses(),
        query: { year: this.$props.year, month: this.$props.month },
        replace: true,
      });
    },
  },
  components: {
    CustomLargeCard,
    CustomMiddleCard,
    CustomCard,
  },
};
</script>

<style scoped lang="scss">
.margin {
  margin-top: 2.2rem;
}

.span {
  display: flex;
  width: 100%;
  height: 0.15rem;
  border-radius: 20%;
  background-color: var(--light-4);
}

.body {
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

@media (min-width: 725px) {
  .body {
    justify-content: initial;
    align-items: initial;
  }
}

.imagem-analytics {
  background-image: url("../../../../assets/images/ilus_analitica.svg");
  background-repeat: no-repeat;
  background-position: 85% 90%;
}

.flex-container {
  max-width: 70rem;
  display: grid;
  border-style: none;
  grid-column: 0;
  justify-content: center;
  align-items: center;
}

@media (min-width: 725px) {
  .flex-container {
    grid-template-columns: auto 300px;
    justify-content: initial;
    align-items: initial;
  }
}
.custom-title {
  text-align: left;
}

@media (max-width: 577px) {
  .custom-title {
    text-align: center !important;
  } 
}
</style>
