import AssemblyParticipation from "../../modules/assembly-management/views/AssemblyParticipation.vue";
import { PermissionList } from "../../static-data/PermissionList";
import RouterViewRender from "../../utils/routes/router-view-render";
import { IsAbleNavigation } from "../utils/is-able-navigation";
const AssenblyPageRoutes = {
  path: "assembly-participation",
  component: RouterViewRender,
  beforeEnter: (to, from, next) =>
    IsAbleNavigation(to, from, next, PermissionList.REGISTER_MENU),
  children: [
    {
      path: "",
      component: AssemblyParticipation,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};
export default AssenblyPageRoutes;