<template>
  <div class="card-container">
    <b-card no-body class="middle-card--control-size" border-variant="light">
      <b-card-body class="middle-card--body">
        <b-card-text>
          <h4>
            {{ $props.title }}
          </h4>
          <b-row class="mt-3 p2">
            <b-col class="middle-card--begin">Bruto</b-col>
            <b-col class="middle-card--end">
              <CustomMaskLabel
                :initialValue="this.gross"
                :options="numberOptions"
                :cssClass="colorGray"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1 p2" v-if="$props.invoiceValue">
            <b-col class="middle-card--begin">NF</b-col>
            <b-col class="middle-card--end">
              <CustomMaskLabel
                :initialValue="this.invoice"
                :options="numberOptions"
                :cssClass="colorGray"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1 p2" v-if="$props.invoiceValue">
            <b-col class="middle-card--begin">Valor a receber</b-col>
            <b-col class="middle-card--end">
              <CustomMaskLabel
                :initialValue="this.receivable"
                :options="numberOptions"
                :cssClass="colorGreen"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import CustomMaskLabel from "../../../../components/forms/custom-mask-label/CustomMaskLabel.vue";

const NumberOptions = {
  numeral: true,
  numeralDecimalMark: ",",
  delimiter: ".",
  prefix: "R$ ",
  onValueChanged: InvalidEventHandler,
};

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};

export default {
  name: "custom-middle-card",
  props: ["title", "grossValue", "amountReceivable", "invoiceValue"],
  components: {
    CustomMaskLabel,
  },
  data() {
    return {
      colorGreen: "color-primary",
      colorGray: "color-gray-2",
      gross: null,
      receivable: null,
      invoice: null,
      numberOptions: {
        ...NumberOptions,
        onValueChanged: this.onChangePhone,
      },
    };
  },
  mounted() {
    this.gross = this.$props.grossValue ? this.$props.grossValue : "0,00";
    this.receivable = this.$props.amountReceivable
      ? this.$props.amountReceivable
      : "0,00";
    this.invoice = this.$props.invoiceValue ? this.$props.invoiceValue : "0,00";
  },
};
</script>

<style scoped lang="scss">
div.card {
  border-radius: 1rem;
}

.middle-card {
  &--control-size {
    height: 13.5rem;
    width: 30rem;
  }

  &--begin {
    color: var(--grey-1);
    text-align: left;
  }

  &--end {
    text-align: right;
  }
}
</style>
