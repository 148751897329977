<template>
  <div class="app-content" :class="isAnalitics ? '' : 'app-content-background'">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    isAnalitics: {
      get() {
        return this.$route.path.indexOf("analytics") != -1;
      }
    }
  }  
};
</script>

<style lang="scss" scoped>
.app-content {
  height: 89vh;
  width: 100vw;
  padding: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: percentage($number: 0.6);
  margin-top: 5rem;

  &-background {
    background-image: url("../../assets/images/ilustracao_completa.svg");
  }

  @include media-breakpoint-down(xs) {
    padding: 1.4rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 1.4rem;
  }
}
</style>
