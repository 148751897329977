<template>
  <div class="custon-cards-resultado-coletivo">
    <div class="custom-header">
      <p class="custom-title" :class="onGetClassCustomColor()">
        {{ this.$props.title }}
      </p>
    </div>
    <div class="custom-body">
      <div>
        <p v-if="this.$props.infoPorcentage" :class="onGetClassInfoPorcentage()"> {{ this.$props.infoPorcentage }} </p>
        <p
          v-text="this.$props.porcentage"
          class="d-flex align-items-start p-c-19"
          :class="onGetClassCustomColor()"
        ></p>
        <p v-if="this.$props.infoMeta" :class="onGetClassInfoPorcentage()"> {{ this.$props.infoMeta }}</p>
      </div>
    </div>
    <div class="custom-footer">
      <p
        class="p custon-pointer saiba-mais"
        @click="onActioHandler(typeActionHandler)"
      >
        <img
          class="img-saiba-mais"
          src="../../../assets/images/MDI-information.svg"
        />
        Saiba mais
      </p>
    </div>
  </div>
</template>

<script>
import { isMobile } from '../../../utils/mobile/mobile';

export default {
  props: [
    "title",
    "customColor",
    "porcentage",
    "infoPorcentage",
    "infoMeta",
    "onAction",
    "typeAction"
  ],
  setup(props) {
    const onActioHandler = function(event) {
      props.onAction(event);
    };

    return { onActioHandler };
  },
  data() {
    return {
      typeActionHandler: "",
    }
  },
  components: {},
  mounted() {
    this.typeActionHandler = this.$props.typeAction ? this.$props.typeAction : "";
  },
  methods: {
    onGetClassCustomColor() {
      return this.$props.customColor;
    },
    onGetClassInfoPorcentage() {
      let resp = "p-16-custom "
      if (isMobile()) {
        return resp + "p-4-m " + this.onGetClassCustomColor()
      }

      return resp + this.onGetClassCustomColor();
    }
  }
};
</script>
<style lang="scss" scoped>
.custon-cards-resultado-coletivo {
  border-radius: 10px;
  background-color: #ffffff;
  padding-left: 1rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 30rem;
  height: 17.8rem;
}
.custom-header {
  height: 15%;
}
.custom-body {
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.custom-footer {
  height: 10%;
}
.img-saiba-mais {
  height: 1rem;
  width: 1rem;
}
.custon-pointer {
  cursor: pointer;
}
.saiba-mais {
  width: 10rem;
}
.custom-title {
  font-size: multiplier-default-font-size(3); // 16
}
.secondary-green {
  color: var(--secondary-green);
}
.is-mobile {
  .p-4-m {
    font-size: 1rem;
  }
  .p-6 {
    font-size: 1.4rem;
  }

  .p10 {
    font-size: 1.4rem;
  }

  .p19 {
    font-size: 2rem;
  }

  .p-c-19 {
    font-size: 2rem;
  }

  .p-c-199 {
    font-size: 1.4rem;
  }
  .p-199-m {
    font-size: 1rem;
  }
  .p-199 {
    font-size: 1.2rem;
  }

  .p-c-200 {
    font-size: multiplier-default-font-size(19); // 48
  }

  .p-200 {
    font-size: multiplier-default-font-size(3); // 16px
  }

  .p-201 {
    font-size: 1.4rem;
  }
  .p-201-gd {
    font-size: 1.1rem;
  }
  .p-202 {
    font-size: 1rem;
    margin-bottom: 0rem;
  }
}
.p10 {
  font-size: multiplier-default-font-size(3); // 16
}

.p19 {
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-19 {
  font-size: multiplier-default-font-size(19); // 48
}

.p-c-199 {
  font-size: multiplier-default-font-size(19); // 48
}

.p-199 {
  font-size: multiplier-default-font-size(3); // 16px
}

.p-c-200 {
  font-size: multiplier-default-font-size(19); // 48
}

.p-200 {
  font-size: multiplier-default-font-size(3); // 16px
}

.p-201 {
  font-size: multiplier-default-font-size(5); // 20px
}

.p-accumulated {
  font-size: multiplier-default-font-size(2); //14px
}

.p-202 {
  font-size: multiplier-default-font-size(2);
  margin-bottom: 0rem;
}

.p-16-custom {
  font-size: multiplier-default-font-size(3); // 16
}
.green-3 {
  color: var(--green-3);
}
.primary {
  color: var(--primary);
}
.orange-2 {
  color: var(--orange-2);
}

@media (max-width: 992px) {
  .p-16-custom {
    font-size: multiplier-default-font-size(2); // 14
  }

  .custon-cards-resultado-coletivo {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

@media (max-width: 768px) {
  .p-16-custom {
    font-size: multiplier-default-font-size(1); // 12
  }

  .p-c-19 {
    font-size: multiplier-default-font-size(17); 
  }
}

@media (max-width: 560px) {
  .p-16-custom {
    font-size: multiplier-default-font-size(0); // 12
  }

  .p-c-19 {
    font-size: multiplier-default-font-size(15); 
  }
}
@media (max-width: 499px) {
  .p-c-19 {
    font-size: multiplier-default-font-size(13); 
  }
}
</style>
