<template>
    <div ref="container" v-b-tooltip.hover :title="text">
      {{ truncatedText }}
    </div>
  </template>
  
  <script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      },
      minLength: {
        type: Number,
        default: 10
      },
      maxLength: {
        type: Number,
        default: 50
      }
    },
    data() {
      return {
        containerWidth: 0,
        calculatedLength: this.maxLength
      };
    },
    computed: {
      truncatedText() {
        return this.text.length > this.calculatedLength
          ? this.text.substring(0, this.calculatedLength) + '...'
          : this.text;
      }
    },
    methods: {
      updateTextLength() {
        if (this.$refs.container) {
          this.containerWidth = this.$refs.container.parentNode.clientWidth - 10;               
          
          this.calculatedLength = Math.max(
            this.minLength,
            Math.floor(this.containerWidth / 10)
          );  
          
          this.calculatedLength = Math.min(this.calculatedLength, this.maxLength);
        }
      }
    },
    mounted() {
      this.updateTextLength();
    },
  };
  </script>
  
  <style scoped>
  span {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
  </style>
  