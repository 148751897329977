<template>
    <div>
        <AssemblyLayout            
            viewComponent="AssemblyUpdate"
            :cardInfo="this.cardInfo"
            :leftAction="this.leftAction"
        />
    </div>
</template>
<script>
import Vue from "vue";
import AssemblyLayout from "../components/AssemblyLayout.vue";
import RoutesPaths from "../../../router/router-structure/routes-paths";

Vue.component("AssemblyUpdate", () =>
  import("../components/forms/AssemblyUpdate.vue")
);

export default {
  components: {
    AssemblyLayout,
  },
  data: function() {
    return {
      leftAction: {
        text: "Editar Assembleia",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        icon: "file-document-outline.svg",
        title: "Participação Assembleia",
        description: "Registre a participação dos cooperados nas assembleias e reuniões",
      },
    };
  },
  methods: {
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.contentManagement.assemblyManagement(),
        replace: true,
      });
    },
  },
    
}
</script>