<template>
  <div id="custom-card-analytics" class="card-container">
    <b-card no-body class="custom-card--size-control " border-variant="light">
      <b-card-body>
        <b-card-text>          
          <b-row class="mb-2">
            <b-col class="middle-card--begin">
              <h4>
                {{ $props.title }}
              </h4>
            </b-col>
            <b-col class="middle-card--end">
              <div :title='tooltipText'
                @click="exibirDetalhes()"
                class="custom-card--pointer custom-value-color custom-card--open-link"
              >
            </div>
              
            </b-col>
          </b-row>
          <b-row v-show="this.$props.isShowLink">
            <b-col>
              <div class="custom-card--background" 
              :class="type == 'backbeat' ? 'custom-card--background--backbeat' : 'custom-card--background--gloses'"></div>
            </b-col>
            <b-col class="custom-value" align-self="end">
              <h2 class="custom-value-color">
                <CustomMaskLabel
                  :initialValue="this.receivable"
                  :options="numberOptions"
                  :cssClass="color"
                />
              </h2>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import CustomMaskLabel from "../../../../components/forms/custom-mask-label/CustomMaskLabel.vue";

const NumberOptions = {
  numeral: true,
  numeralDecimalMark: ",",
  delimiter: ".",
  prefix: "R$ ",
  onValueChanged: InvalidEventHandler,
};

const InvalidEventHandler = () => {
  console.warn("Invalid onChange handler");
};
export default {
  name: "custom-middle-card",
  props: ["title", "amountReceivable", "onDetailClick", "isShowLink", "type", "tooltipText"],
  components: {
    CustomMaskLabel,
  },
  data() {
    return {
      color: "color-primary",
      numberOptions: {
        ...NumberOptions,
        onValueChanged: this.receivable,
      },
      receivable: null,
    };
  },
  methods: {
    exibirDetalhes() {
      this.$props.onDetailClick();
    },
  },
  mounted() {
    this.type = this.$props.type;
    this.tooltipText = this.$props.tooltipText;
    this.receivable = this.$props.amountReceivable
      ? this.$props.amountReceivable
      : "000,00";
  },
};
</script>

<style scoped lang="scss">
div.card {
  border-radius: 1rem;
}

.custom-table {
  width: 100%;
}

.custom-value {
  text-align: right;
}
.custom-value-color {
  color: var(--primary);
}
.custom-card {
  &--pointer {
    cursor: pointer;
  }

  &--size-control {
    height: 15.9rem;
    width: 30rem;
  }
  
  &--open-link {
    background-image: url("../../../../assets/icons/open-link.svg");    
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 25px;
  }

  &--background {    
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 77px;
    background-size: contain;
    margin-left: -5px;
    opacity: 0.2;

    &--backbeat {
      background-image: url("../../../../assets/icons/backbeat-outline.svg");
    }

    &--gloses {
      background-image: url("../../../../assets/icons/document-card-outline.svg");
    }

  }

}
</style>

<style lang="scss">
#custom-card-analytics .card-body {
  padding-top:30px;
  padding-bottom:30px;
  padding-left: 35px;
  padding-right: 35px;
}
</style>
