import CertificationManagementForm from "../../modules/certification-management/views/CertificationManagementForm.vue"
import CertificationManagement from "../../modules/certification-management/views/CertificationManagement.vue"

import RouterViewRender from "../../utils/routes/router-view-render";

const CertificationManagementRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
   {
      path: "certification-management",
      component: CertificationManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "certification-management-form",
      component: CertificationManagementForm,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "certification-management-form/:id",
      component: CertificationManagementForm,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default CertificationManagementRoutes;
