import AssemblyManagement from "../../modules/assembly-management/views/AssemblyManagement.vue"
import AssemblyManagementCreate from "../../modules/assembly-management/views/AssemblyManagementCreate.vue"
import AssemblyParticipantsReport from "../../modules/assembly-management/views/AssemblyParticipantsReport.vue"
import AssemblyManagementUpdate from "../../modules/assembly-management/views/AssemblyManagementUpdate.vue";

import RouterViewRender from "../../utils/routes/router-view-render";

const AssemblyManagementRoutes = {
  path: "",
  component: RouterViewRender,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: "assembly-management",
      component: AssemblyManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "assembly-management-form/:id",
      component: AssemblyManagementUpdate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "assembly-management-form",
      component: AssemblyManagementCreate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "assembly-participants-report",
      component: AssemblyParticipantsReport,
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

export default AssemblyManagementRoutes;
