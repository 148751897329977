<template>
  <div>
    <TableVisualizationLayout :leftInfo="leftInfo" :leftAction="leftAction">
      <template slot="headerContent">
        <div class="header-container">
          <div class="label-filter">CRM:</div>
          <div :class="isMobile() ? 'div-container-mobile' : 'div-container'">
            <b-form-tags
              input-id="tags-basic"
              v-model="filterCrms"
              placeholder=""
              add-button-text="Adicionar"
              class="custom-tags"
            ></b-form-tags>
          </div>
          <div :class="isMobile() ? 'div-container-mobile' : 'div-container'">
            <b-button
              class="my-4 "
              variant="primary"
              @click="onGetAssemblyParticipantsList()"
              v-show="!this.loading"
            >
              Gerar Relatório
            </b-button>
          </div>

          <div
            class="align-right"
            :class="isMobile() ? 'div-container-mobile' : 'div-container'"
            @click="downloadAssemblyParticipants()"
            v-show="!this.loading"
          >
            <div
              :class="isMobile() ? 'class-download' : 'class-download-right'"
            >
              <div
                class="d-flex justify-content-center align-items-center img-custon-principal"
              >
                <img
                  class="img-custon"
                  src="@/assets/icons/MDI-microsoft-excel.svg"
                />
              </div>
              <div class="custom-text d-flex align-items-center">
                Download
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="table">
        <div class="scrollme">
          <b-table
            class="table-custom-training spaced-text"
            :fields="fields"
            :items="items"
            v-show="!this.loading"
            show-empty
            empty-text="Nenhuma informação para exibir!"
          >
          </b-table>
        </div>
        <div v-show="this.loading">
          <b-skeleton-table :rows="10" :columns="6"></b-skeleton-table>
        </div>
      </template>
      <template slot="footerContent">
        <div
          class="d-flex bd-highlight mb-3 justify-content-between"
          :class="isMobile() ? 'flex-column-reverse' : 'flex-row'"
          style="width: 100%;"
        >
          <div class="mx-auto">
            <b-pagination
              v-model="page"
              :total-rows="totalSize"
              :per-page="perPage"
              aria-controls="my-table"
              size="lg"
            />
          </div>
        </div>
      </template>
    </TableVisualizationLayout>
  </div>
</template>
<script>
import {
  getAssemblyParticipantsList,
  downloadAssemblyParticipants,
} from "../../../services/assembly-management/assembly-management-service";

import RoutesPaths from "../../../router/router-structure/routes-paths";
import TableVisualizationLayout from "../../../layouts/TableVisualizationLayout.vue";
import { createToast } from "../../../components/toast/toast";
import { inject } from "@vue/composition-api";
import { isMobile } from "../../../utils/mobile/mobile";
import { anchorBase64Download } from "../../../utils/anchor-base64-download/anchor-base64-download";

export default {
  setup() {
    const globalLoading = inject("globalLoading");

    return { globalLoading };
  },
  components: { TableVisualizationLayout },
  mounted() {},
  watch: {
    page: {
      handler() {
        this.onGetAssemblyParticipantsList();
      },
    },
  },
  data: function() {
    return {
      leftInfo: "",
      loading: false,
      filterCrms: [],
      disableButton: true,
      leftAction: {
        text: "Relatório de Participações em Assembleias por CRM",
        onClick: this.leftActionClickHandler,
      },
      cardInfo: {
        icon: "file-document-outline.svg",
        title: "Participação Assembleia",
        description:
          "Registre a participação dos cooperados nas assembleias e reuniões",
      },
      fields: [
        {
          key: "crm",
          label: "CRM",
          thStyle: { width: "10%" },
        },
        {
          key: "name",
          label: "Nome",
          thStyle: { width: "30%" },
        },
        {
          key: "event",
          label: "Evento",
          thStyle: { width: "40%" },
        },
        {
          key: "date",
          label: "Data",
          thStyle: { width: "10%" },
        },
      ],
      items: [],
      page: 1,
      perPage: 10,
      totalSize: 10,
    };
  },
  methods: {
    isMobile: isMobile,
    async onGetAssemblyParticipantsList() {
      try {
        this.loading = true;

        const participantsResponse = await getAssemblyParticipantsList(
          this.filterCrms,
          this.page - 1,
          this.perPage
        );
        this.items = participantsResponse.items;
        this.totalSize = participantsResponse.totalItems;
        this.loading = false;
      } catch (error) {
        createToast("Erro", "Não foi possível gerar o relatório.");
        console.error("onGetAssemblyParticipantsList", error);
        this.loading = false;
      }
    },
    async downloadAssemblyParticipants() {
      try {
        this.globalLoading = true;
        const reportResponse = await downloadAssemblyParticipants(
          this.filterCrms
        );
        anchorBase64Download(
          null,
          "application/xls",
          "Relatório de Participações em Assembleias por CRM" +
            reportResponse.extension,
          reportResponse.fileBase64
        );
        this.globalLoading = false;
      } catch (error) {
        createToast("Erro", "Não foi possível gerar o relatório.");
        console.error("downloadAssemblyParticipants", error);
        this.globalLoading = false;
      }
    },
    leftActionClickHandler() {
      this.$router.push({
        path: RoutesPaths.contentManagement.assemblyManagement(),
        replace: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header-container {
  display: flex;
  align-items: center; /* Alinha verticalmente todos os itens */
  justify-content: flex-start; /* Alinha os itens no início da linha */
  flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha, se necessário */
  margin: 1rem 0;
}
.div-container {
  flex: 1; /* Ocupa 100% no mobile */
  max-width: 50%; /* Limita a largura em telas maiores */
  margin-right: 0.5rem; /* Espaçamento entre os elementos */
  width: 50rem;
}
.div-container-mobile {
  @extend .div-container;
  flex: 1 1 50%;
}
.form-control {
  line-height: 3 !important;
}
.b-form-tags-input {
  margin-right: 0.5rem !important;
}
.b-form-tag-content {
  font-size: 1.2rem; /* Aumenta o tamanho da fonte do componente */
}
.class-download {
  cursor: pointer;
  height: 4.2rem;
  width: 13.8rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #00995d 0% 0% no-repeat padding-box;
}
.class-download-right {
  @extend .class-download; /* Reaproveita os estilos da classe base */
  margin-left: auto; /* Adiciona o novo estilo */
}
.img-custon-principal {
  width: 5rem;
}
.custom-text {
  color: var(--background-1);
  font-size: multiplier-default-font-size(3); // 16
}
.label-filter {
  font: normal normal normal 12px/14px Trebuchet MS;
  letter-spacing: 0px;
  color: #7a7e83;
  text-transform: uppercase;
  opacity: 1;
  margin-right: 1rem;
}
</style>
