<script>
import CustomCardInfo from "../../../components/custom-info-card/CustomInfoCard.vue"
import FormTemplate from "../../../templates/FormTemplate.vue";
import NavigationButton from "../../../components/navigation-button/NavigationButton.vue";
import { ref, watch } from "@vue/composition-api";
import { getNewByName } from "../../../utils/news/news-utils";

export const FormLayout = {
  props: [
    "cardInfo",
    "leftAction",
    "leftLinkNavigation",
    "navigation",
    "viewComponent",
    "onChangeTab",
  ],
  setup(props) {
    const tabIndex = ref();

    watch(tabIndex, (newValue) => {
      props.onChangeTab && props.onChangeTab(newValue);
    });

    return { tabIndex };
  },
  components: {
    FormTemplate,
    CustomCardInfo,
    NavigationButton,
  },
  methods: {
    getClassNew(featureName) {
      const currentNew = getNewByName(featureName);
      if (currentNew) {
        return "tab-new";
      }
      return;
    },
  },
  render(createElement) {
    const invalidHandler = (e) => {
      console.warn("Invalid handler!", e);
    };

    const leftAction = {
      text: "Invalid Text!",
      onClick: invalidHandler,
      ...this.$props.leftAction,
    };

    const leftNavigation = this.$props.leftLinkNavigation;

    const cardInfo = {
      headerIcon: "",
      title: "Invalid Title!",
      description: "Invalid description!",
      ...this.$props.cardInfo,
      onCardClick: this.$props?.cardInfo?.onCardClick || invalidHandler,
    };

    const layoutContent = createElement(this.$props.viewComponent);

    return (
      <FormTemplate>
        <template slot="leftAction">
          <NavigationButton actionClickHandler={leftAction.onClick}>
            <template slot="icon">
              <i class="fas fa-arrow-left"></i>
            </template>
            <template slot="text">{leftAction.text}</template>
          </NavigationButton>
        </template>
        <template slot="infoCard">
          {this.$props.cardInfo && (
            <CustomCardInfo
              class="text-center mb-4"
              headerIcon={cardInfo.headerIcon}
              icon={cardInfo.icon}
              title={cardInfo.title}
              description={cardInfo.description}
              onCardClick={cardInfo.onCardClick}
            />
          )}

          {leftNavigation && (
            <b-card
              class="small-card-history mb-4"
              onClick={leftNavigation}
              border-variant="light"
            >
              <h5>Histórico de solicitações</h5>
            </b-card>
          )}
        </template>
        <template slot="navigation">          
            {layoutContent}          
        </template>
      </FormTemplate>
    );
  },
};

export default FormLayout;
</script>

<style scoped lang="scss">
.small-card-history.card {
  width: 19rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 3rem;
  cursor: pointer;
  & > .card-body {
    text-align: center;
    font-weight: 600;
  }

  h5 {
    transition: color 0.65s ease;
  }

  &:hover,
  h5:hover {
    transition: color 0.25s ease;
    color: var(--primary);
  }
}
</style>
<style lang="scss">

.tab-new::after {
  content: '';
  margin-bottom: 14px;
  border-radius: 5px;
  top: 28px;
  left: 1082px;
  width: 10px;
  height: 10px;
  background: #b1d34b 0% 0% no-repeat padding-box;
  opacity: 1;
}
</style>
